import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/navigation";
import {ReactComponent as Prev} from "../../svg/prev.svg";
import {ReactComponent as Next} from "../../svg/next.svg";
import {useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import PriceInPill from "../product/priceInPill";

const ItemsSlider = ({slides}) => {

    const [swiper, setSwiper] = useState(null)
    return <div
        style={{padding: "0 50px"}}
        className={"position-relative"}
    >
        <Button
            variant={"link"}
            className={"p-0 navigation-button navigation-button--next d-none d-sm-block"}
            onClick={() => {
                swiper?.slideNext()
            }
            }
        >
            <Next/>

        </Button>
        <Button
            variant={"link"}
            className={"p-0 navigation-button navigation-button--prev d-none d-sm-block"}
            onClick={() => {
                swiper?.slidePrev()
            }
            }
        >
            <Prev/>

        </Button>
        <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
                1200: {
                    slidesPerView: 4
                },
                992: {
                    slidesPerView: 2
                }
            }}

        >
            {
                slides?.map((item, index)=>{
                    const imgUrl = item.images[0]?.resized?.galleryThumb ?? item.images[0]
                    return (
                        <SwiperSlide className={"d-flex flex-column"} key={index}>


                            <Link to={"/product/" + item.id} className={"fz-14"}>
                                <img  src={imgUrl.default} alt="" style={{width: "100%" , height: "auto", aspectRatio: "1/0.7", objectFit: "cover", objectPosition: "center"}}/>
                                {item.name}
                            </Link>
                            <PriceInPill priceList={item.priceList} className={'fz-14 mt-1 '}  style={{width: "fit-content"}}/>

                        </SwiperSlide>
                    )
                })
            }


        </Swiper>
    </div>
}
export default ItemsSlider