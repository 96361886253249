import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

import Button from "react-bootstrap/Button";
import ChoosenFilter from "./filterElements.js/choosenFilter";
import { Badge } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import FilterElement from "./filterElements.js/filterElement";
import Form from "react-bootstrap/Form";
import useDidUpdateEffect from "../../../customHooks/useDidUpdateEffect";
import { useQuery } from "@tanstack/react-query";

function ProductsFilters() {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [filterValues, setFilterValues] = useState([]);
  const [chosenFilters, setChosenFilters] = useState([]);
  const [isActive, setActive] = useState(false);
  const toggleClass = () => {
    setActive(!isActive);
  };

  const addChosen = (name, value, category, valName) => {
    setChosenFilters((state) => [
      ...state,
      {
        label: category,
        value: valName,
        name: name,
        queryVal: value,
      },
    ]);
  };

  function getData(d) {
    return axios
      .get(
        `/filters${
          d.queryKey[0] !== null ? "?filters[category][]=" + d.queryKey[0] : ""
        }`
      )
      .then((response) => {
        return response.data.items; // setLoading(false)
      });
  }
  const { data, isPending } = useQuery({
    queryKey: [searchParams.get("filters[category][]"), i18n.language],
    queryFn: getData,
    staleTime: 1000 * 60 * 60,
  });
  useEffect(() => {
    let v = [];
    searchParams.forEach((value, key) => {
      v.push({
        name: key,
        value,
      });
    });
    setFilterValues(v);
  }, []);
  useEffect(() => {
    searchParams?.forEach((value, key) => {
      data?.forEach((category) => {
        if (key.includes(category.items[0].queryName)) {
          const filter = category.items.find(
            (item) =>
              `filters[${item.queryName}][]` === key &&
              item.queryValue === value
          );
          addChosen(
            `filters[${filter.queryName}][]`,
            value,
            category.name,
            filter.name
          );
        }
      });
    });
  }, [data]);

  const removeChosen = (name, value) => {
    console.log(chosenFilters);
    setChosenFilters((state) => {
      const newState = state.filter((item) => {
        if (item.queryVal === value) {
          return false;
        } else {
          return true;
        }
      });

      return newState;
    });

    setFilterValues((state) => {
      const newState = state.filter((item) => {
        const isClickeditem = item.value === value && item.name === name;
        return !isClickeditem;
      });

      return newState;
    });
  };

  const handleCheckbox = (e, filterName, queryName) => {
    const { name, value, checked } = e.target;

    if (checked) {
      addChosen(name, value, filterName, queryName);
      setFilterValues((state) => [
        ...state,
        {
          name,
          value,
        },
      ]);
    } else {
      removeChosen(name, value);
    }
  };

  const isChecked = (name, value) =>
    filterValues?.some((item) => item.value === value && item.name === name);

  const clearFilters = () => {
    setChosenFilters([]);
    setFilterValues([]);
  };

  function setFilters() {
    setSearchParams((state) => {
      const currentState = state;
      const newState = new URLSearchParams();
      currentState.forEach((val, key) => {
        console.log(val, key);
        if (!key.includes("filters") || key === "filters[category][]") {
          newState.append(key, val);
        }
      });

      filterValues.forEach((filter) => {
        if (
          filter.name !== "filters[category][]" &&
          !filter.name.includes("order")
        ) {
          newState.append(filter.name, filter.value);
        }
      });
      newState.set("page", "1");
      return newState;
    });
  }

  return (
    // należy dodać klasę filters-ready jezeli zostana wybrane style
    <div
      className={` h-40 search-togglers rounded-pill border border-dark ms-3 me-xl-3 ${
        isActive ? "opened-parent-absolute" : "closed-parent-absolute"
      }`}
    >
      <span
        className={`arrow-after arrow-right pe-5 ${
          chosenFilters?.length > 0 ? "" : "filter-icon"
        } rotate w-100 h-100 d-flex align-items-center ${
          isActive ? "opened" : "closed"
        }`}
        onClick={toggleClass}
      >
        {/* badge ma ma zablokowane wyswietlanie w stylach */}
        {chosenFilters?.length ? (
          <Badge bg="primary" text="dark" className="badge-circle">
            {chosenFilters?.length}
          </Badge>
        ) : null}
        {t("Pop.filter")}

        {chosenFilters.length ? (
          <Button
            className="icon-action "
            variant="link"
            onClick={() => {
              clearFilters();
              // getProducts()
            }}
          >
            {" "}
            <img src="/img/close-red.svg" alt="" />
          </Button>
        ) : null}
      </span>
      <div className="filters-background"></div>
      <div className="filter-container">
        <Form className=" h-100">
          <div className="filter-wrapper position-relative">
            <PerfectScrollbar>
              <div className="choosen-filters d-flex flex-wrap">
                {chosenFilters.map((item, index) => (
                  <ChoosenFilter
                    label={item.label}
                    value={item.value}
                    key={index}
                    onClick={() => removeChosen(item.name, item.queryVal)}
                  />
                ))}
              </div>
              <div className="to-choose-filters">
                {isPending ? (
                  <p>loading</p>
                ) : (
                  data?.map((filter, index) => (
                    <FilterElement dropdown={filter.name} key={index}>
                      {filter.items?.map((item, index) => (
                        <Form.Check
                          key={index}
                          type="checkbox"
                          id={`${item.queryName}-${item.queryValue}`}
                          label={item.name}
                          name={`filters[${item.queryName}][]`}
                          onChange={(e) =>
                            handleCheckbox(e, filter.name, item.name)
                          }
                          value={item.queryValue}
                          checked={isChecked(
                            `filters[${item.queryName}][]`,
                            item.queryValue
                          )}
                          className={"mb-2"}
                        />
                      ))}
                    </FilterElement>
                  ))
                )}
              </div>
            </PerfectScrollbar>
            <div className="d-flex filter-buttons justify-content-between align-items-center">
              <div className="filter-buttons-wrapper d-flex justify-content-between w-100">
                <Button
                  variant="success icon-left"
                  onClick={() => {
                    setFilters();
                    toggleClass();
                  }}
                >
                  {t("ready")}
                </Button>
                <Button variant="danger icon-left" onClick={clearFilters}>
                  {t("Navbar.delete_filter")}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ProductsFilters;
