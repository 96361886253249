import i18next from "i18next";

import XLSX from "sheetjs-style";

function notDeliveredXlsx(data) {
  const wb = XLSX.utils.book_new();
  const { t } = i18next;

  const ws = XLSX.utils.aoa_to_sheet([
    [
      t("Xlsx.product"),
      "EAN",
      t("Xlsx.quantity"),
      t("Xlsx.unit"),
      t("Orders.order_number"),
      t("Orders.order_number_client"),
      t("Orders.order_date"),
    ],
    ...data?.map((element) => [
      element.product.name,
      element.product.ean,
      element.quantity,
      element.unit,
      element.orderNumber,
      element.clientOrderNumber,
      element.orderDate,
    ]),
  ]);

  ws["A1"].s = {
    font: {
      bold: true,
    },
  };
  ws["B1"].s = {
    font: {
      bold: true,
    },
  };
  ws["C1"].s = {
    font: {
      bold: true,
    },
  };
  ws["D1"].s = {
    font: {
      bold: true,
    },
  };
  ws["E1"].s = {
    font: {
      bold: true,
    },
  };
  ws["F1"].s = {
    font: {
      bold: true,
    },
  };
  ws["G1"].s = {
    font: {
      bold: true,
    },
  };

  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(wb, `${t("Orders.undelivered")}.xlsx`);
}

export default notDeliveredXlsx;
