import { Link, useParams } from "react-router-dom";
import HeaderBar from "../components/headerBar/headerBar";
import { Col, Overlay, Popover, Row, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomTable from "../components/table/customTable";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import CommentPreview from "../components/reclamation/commentPreview";
import useOutsideClick from "../customHooks/useOutsideClick";
import { ReactComponent as Dots } from "../svg/vertical-dots.svg";
import { ReactComponent as Download } from "../svg/download.svg";
import { useTranslation } from "react-i18next";

const Files = ({ files }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const { t } = useTranslation();

  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setShow(false);
  });

  return (
    <>
      <Dots
        ref={target}
        onClick={() => setShow(!show)}
        className={"cursor-pointer"}
      />

      <Overlay ref={ref} target={target.current} show={show}>
        <Popover id="popover-basic">
          <Popover.Header as="h3">Pliki</Popover.Header>
          <Popover.Body>
            {files?.map((element, i) => (
              <a
                key={i}
                className="d-flex align-items-center my-2 no-underline text-break"
                href={element.url}
                target="_blank"
                rel="noreferrer"
              >
                {element.customFileName}
              </a>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
const ReclamationDetails = () => {
  const { reclamationId } = useParams();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      title: t("Reclamations.name"),
      dataKey: "product",
      width: "17.5%",
      render: (product) => <>{product.name}</>,
    },
    {
      title: "EAN",
      dataKey: "product",
      width: "12.5%",
      render: (product) => <>{product.ean}</>,
    },
    {
      title: t("Reclamations.count"),
      dataKey: "quantity",
      width: "10%",
    },
    {
      title: t("Reclamations.unit"),
      dataKey: "shortUnitName",
      width: "12.5%",
    },
    {
      title: t("Reclamations.reason"),
      dataKey: "reason",
      width: "12.5%",
      render: (reason) => <>{reason.name}</>,
    },
    {
      title: t("Reclamations.expectations"),
      dataKey: "reason",
      width: "12.5%",
      render: (reason) => reason.expectancy?.map((item) => <>{item.name}</>),
    },
    {
      title: t("Reclamations.status"),
      dataKey: "status",
      width: "12.5%",
    },
    {
      title: t("Reclamations.details"),
      dataKey: "clientComment",
      width: "10%",
      render: (value, product) => (
        <>
          <CommentPreview comment={value} />
          <Files files={product.files} />
        </>
      ),
    },
    {
      title: t("Reclamations.comment"),
      dataKey: "sapComment",
      width: "10%",
      render: (value) => <CommentPreview comment={value} />,
    },
  ];
  const getData = () => {
    axios
      .get("/complaint/" + reclamationId)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(getData, []);

  return (
    <>
      <HeaderBar
        header={` ${t("Reclamations.reclamation_nr")} ${
          data.customerComplaintNumber
            ? data.customerComplaintNumber
            : data.complaintNumber
            ? data.complaintNumber
            : "-"
        }`}
        headerBarID="reclamations"
      >
        {/*<Button variant="danger" className="fz-12">*/}
        {/*    Anuluj reklamację*/}
        {/*</Button>*/}
      </HeaderBar>
      <Row>
        <Col className="gray-bg custom-scroll content-container with-header-bar  ">
          <PerfectScrollbar>
            {loading ? (
              <div className="w-100 text-center border border-dark rounded-bottom p-5">
                <Spinner animation={"border"} className={"rounded-circle"} />
              </div>
            ) : (
              <div className="max-1386 pb-5 pt-4">
                <div className="d-flex gap mt-4 pt-3 flex-wrap cards-wrapper">
                  <div className="">
                    <div className="shadow-radius-white p-30 fz-12 text-uppercase">
                      <div className="fw-bold">{t("Reclamations.status")}:</div>
                      <div className="d-flex align-items-center my-2">
                        {data.status ? (
                          <>
                            <div className="circle success small me-1"></div>
                            <div>{data.status}</div>
                          </>
                        ) : (
                          <>
                            <div className="circle warning small me-1"></div>
                            <div>-</div>
                          </>
                        )}
                      </div>
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Reclamations.reclamation_date")}:
                        </div>
                        <div>{data.createComplaintDate}</div>
                      </div>
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Reclamations.date_sell")}:
                        </div>
                        <div>{data.createOrderDate}</div>
                      </div>
                      {
                        data.customerComplaintNumber ?
                            <div className="d-flex">
                              <div className="fw-bold me-1">
                                {t("Reclamations.clientNumber")}:
                              </div>
                              <div>{data.customerComplaintNumber}</div>
                            </div> : null
                      }

                    <div className="d-flex">
                                            <div className="fw-bold me-1">E-mail:</div>
                                            <div>{data.customerEmail ?? ""}</div>
                                        </div>
                                    </div>
                  </div>
                  {data?.complaintsForOrder?.length ? (
                    <div className=" p-3 shadow-radius-white">
                      <span className={"fw-bold"}>
                        {t("Reclamations.reclamations_another")}:
                      </span>
                      <div className="d-flex flex-column">
                        {data?.complaintsForOrder?.map((item, index) => (
                          <Link key={index} to={"/complaints/" + item.id}>
                            {t("Reclamations.day")} {item.createdAt} nr{" "}
                            {item.number}{" "}
                          </Link>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="main-content with-header mt-5">
                  <h2>{t("Reclamations.choosen")}</h2>
                  <CustomTable
                    columns={columns}
                    data={data.products}
                    loading={loading}
                  />
                </div>
              </div>
            )}
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  );
};
export default ReclamationDetails;
