import { FormSelect } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function ProductSort({ className }) {
  const { t } = useTranslation();
  const ref = useRef(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState();

  function handleSort({ target }) {
    const option = target.selectedOptions[0];
    const col = option.dataset.column;
    setSearchParams((state) => {
      state.set("order[0][column]", `${col}`);
      state.set("order[0][dir]", `${option.value}`);
      return state;
    });
  }

  useEffect(() => {
    const column = searchParams.get("order[0][column]");
    const dir = searchParams.get("order[0][dir]");

    for (let item of ref?.current.options) {
      const { value, dataset } = item;
      if (dataset.column === column && value === dir) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    }
  }, [searchParams]);

  return (
    <div className="sort-icon w-224">
      <FormSelect
        ref={ref}
        className={` h-40 search-togglers arrow-after arrow-dropdown-right rounded-pill ${className}`}
        onInput={handleSort}
      >
        <option value={"asc"} data-column={"0"}>
          {t("Sorting.default")}
        </option>
        <option value={"desc"} data-column={"1"}>
          {t("Sorting.min_price_desc")}
        </option>
        <option value={"asc"} data-column={"1"}>
          {t("Sorting.min_price_asc")}
        </option>
        <option value={"desc"} data-column={"2"}>
          {t("Sorting.max_price_desc")}
        </option>
        <option value={"asc"} data-column={"2"}>
          {t("Sorting.max_price_asc")}
        </option>
        <option value={"asc"} data-column={"3"}>
          {t("Sorting.product_name_asc")}
        </option>
        <option value={"desc"} data-column={"3"}>
          {t("Sorting.product_name_desc")}
        </option>
      </FormSelect>
    </div>
  );
}

export default ProductSort;
