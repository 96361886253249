import MainSlider from "../components/home/mainSlider";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Col, Container, Row, Spinner} from "react-bootstrap";
import {useEffect, useRef, useState} from "react";
import Button from "react-bootstrap/Button";
import SideBar from "../components/sidebar/sideBar";
import NavigationMain from "../components/navbars/navbarMain";
import FeedSlider from "../components/home/feedSlider";
import ItemsSlider from "../components/home/itemSlider";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Home = () => {

    const [show, setShow] = useState(false);
    const sidebarToggle = () => {
        setShow(state => !state);
    }
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(false)

    function getData() {
        setLoading(true)
        axios.get("/main-page").then(response => {
            setData(response.data)
            setLoading(false)
        })
    }
const { t } = useTranslation();  

    const [sideBarWidth, setSideBarWidth] = useState(340)
    const sideRef= useRef()
    const setWidth = ()=>{
        setSideBarWidth(sideRef.current?.clientWidth ?? 340)
    }
    useEffect(() => {
        window.addEventListener("resize", setWidth);
        window.addEventListener("load", setWidth);
        return () => {
            window.removeEventListener("resize", setWidth);
            window.addEventListener("load", setWidth);
        };
    }, []);

    useEffect(getData, [])
    return (
      <Container fluid>
        <Row>
          <Col
            xxl="2"
            className={"p-0"}
            style={{ maxWidth: "340px" }}
            ref={sideRef}
          >
            <div
              className={`px-0 sidebar ${
                show ? "open" : ""
              } mw-100 d-flex flex-column`}
              style={{ minWidth: "unset" }}
            >
              <Button
                variant="link"
                className={`sidebar-toggler ${show ? "open" : ""} `}
                onClick={sidebarToggle}
              >
                <div></div>
                <div></div>
                <div></div>
              </Button>
              <SideBar sidebarToggle={sidebarToggle} />
            </div>
          </Col>

          <Col
            style={{
              width: `calc(100vw - ${
                window.innerWidth < 1366 ? 0 : sideBarWidth
              }px`,
            }}
            className={"p-0"}
          >
            <NavigationMain />
            {loading ? (
              <div
                className={
                  "w-100 d-flex justify-content-center align-items-center mt-5"
                }
              >
                <Spinner animation={"border"} />
              </div>
            ) : (
              <PerfectScrollbar style={{ height: "calc(100vh - 80px)" }}>
                  {
                      data ?
                          <MainSlider slides={data?.slides} fullHeight={!data?.news && !data?.products?.length} />
                      : null
                  }
                {data?.news?.length ? (
                  <div className={"section"}>
                    <h2 className={"text-center py-5"}>{t("Others.news")}</h2>
                    <FeedSlider data={data?.news} />
                  </div>
                ) : null}

                {data?.products?.length ? (
                  <div className={"section"}>
                    <h2 className={"text-center py-5"}>{t("Others.news2")}</h2>
                    <ItemsSlider slides={data?.products} />
                  </div>
                ) : null}
              </PerfectScrollbar>
            )}
          </Col>
        </Row>
      </Container>
    );

}
export default Home