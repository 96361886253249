import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import MarketingListItem from "../listMarketing/marketingListItem";

const AccesoriessTabs = ({initItems, heading}) => {

    const {productId} = useParams()
    const [items, setItems] = useState()
    const [categories, setCategories] = useState()
    const handleData = (data) => {
        let categoriesList = []

        data.forEach(item => {

            const categoryExist = categoriesList.some(element => item.category ? element?.id === item.category?.id : true)

            if (!categoryExist) {

                categoriesList.push(item.category)
            }

        })

        setCategories(categoriesList)
        setItems(data)


    }

    const getProducts = () => {
        // setLoading(true)
        axios.get("/product/dedicated/" + productId).then(({data}) => {

            handleData(data.items)
        })
    }
    useEffect(() => {
        if (!initItems) {
            getProducts()
        } else {
            handleData(initItems)
        }
    }, [])
    return (
        <>
            {
                heading && categories?.length ? heading : null
            }
            <Tabs

                id="justify-tab-example"
                className="mb-3"
                justify
                defaultActiveKey={0}
            >
                {
                    categories ? categories.map((category, index) => (
                        <Tab eventKey={index} title={category.name} key={index}>
                            {
                                items?.filter(item => item.category?.id === category.id).map((element, index) => (
                                    <MarketingListItem
                                        key={index}
                                        image="/img/floor.png"
                                        availability="na zamówienie"
                                        item={element}
                                        minOrder="1 opakowanie / 2,16m"
                                        quantity="2"
                                        discount="-30%"
                                        oldPrice="300 €"
                                        productSumValue="433,99 €"
                                        buttonText="Do koszyka"
                                        colorID=""
                                    />
                                ))
                            }
                        </Tab>
                    )) : null
                }


            </Tabs>
        </>

    )
}
export default AccesoriessTabs