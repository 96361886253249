import {useEffect, useState} from "react";

export default function OfferExtraQuantity({item}) {
    const {unitConverters, extraQty, unit,   baseUnit,  priceList,} = item
    const [extraConverted, setExtraConverted] = useState(0)
    const {conditionUnit} = priceList
    useEffect(() => {
        if (conditionUnit === unit) {
            setExtraConverted(extraQty * (unitConverters[unit]?.counter / unitConverters[unit]?.denominator))
        } else {
            setExtraConverted(extraQty * ((unitConverters[unit]?.counter / unitConverters[unit]?.denominator) / (unitConverters[conditionUnit]?.counter / unitConverters[conditionUnit]?.denominator)))

        }
    }, []);

    return <>{`${extraQty} ${unitConverters[unit]?.shortUnitName ?? ""}${isNaN(extraConverted) ? "" : " = " + extraConverted.toFixed(3)} ${conditionUnit === unit ? (baseUnit?.shortUnitName ?? "") : (unitConverters[conditionUnit]?.shortUnitName ?? "")}`}</>
}