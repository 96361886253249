import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/navigation";
import {ReactComponent as Prev} from "../../svg/prev.svg";
import {ReactComponent as Next} from "../../svg/next.svg";
import {useRef, useState} from "react";
import Button from "react-bootstrap/Button";

const FeedSlider = ({data}) => {

    const [swiper, setSwiper] = useState(null)
    console.log(data)
    return <div
        style={{padding: "0 50px"}}
        className={"position-relative"}
    >
        <Button
            variant={"link"}
            className={"p-0 navigation-button navigation-button--next d-none d-sm-block"}
            onClick={() => {
                swiper?.slideNext()
            }
            }
        >
            <Next/>

        </Button>
        <Button
            variant={"link"}
            className={"p-0 navigation-button navigation-button--prev d-none d-sm-block"}
            onClick={() => {
                swiper?.slidePrev()
            }
            }
        >
            <Prev/>

        </Button>
        <Swiper
            onSwiper={setSwiper}
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
                992: {
                    slidesPerView: 2
                }
            }}

        >
            {
                data?.map((item, index)=>{
                    function createMarkup(string) {
                        return {__html: string};
                    }
                    return (
                    <SwiperSlide key={index}>
                        <h3>
                            {item.newsData?.header}
                        </h3>
                        <div
                            dangerouslySetInnerHTML={createMarkup(item.newsData?.text)}
                        />

                    </SwiperSlide>
                )})
            }


        </Swiper>
    </div>
}
export default FeedSlider