import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import NavigationButton from "./navigationButton";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";

const MainSlider = ({ slides, fullHeight }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("load", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.addEventListener("load", handleWindowSizeChange);
    };
  }, [windowWidth]);

  const isMobile = windowWidth <= 992;
  return (
    <Swiper
      slidesPerView={1}
      style={{
        height: `calc(${fullHeight ? 100 : 70}vh - 80px)`,
      }}
      className={"main-slider"}
    >
      {slides?.length > 1 ? (
        <>
          <NavigationButton direction={"prev"} />
          <NavigationButton direction={"next"} />
        </>
      ) : null}
      {slides?.map((slide, index) => {
        const filePath = isMobile
          ? slide.mobileImage?.url ?? ""
          : slide.desktopImage?.url ?? "";
        const match = filePath.match(/([0-9a-z]+$)/i);
        const type = match?.length ? match[0] : "";
        const isVideo = () => {
          switch (type) {
            case "mp4":
              return true;
            case "webm":
              return true;
            case "gif":
              return true;
          }
        };

        return (
          <SwiperSlide key={slide.id}>
            <div
              className={`slide-wraper slide-wraper--${
                isVideo() ? "video" : "img"
              }`}
            >
              {isVideo() ? (
                <video
                  className={"video"}
                  src={filePath}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                />
              ) : null}

              <div
                style={{
                  backgroundImage: isVideo() ? "none" : `url('${filePath}')`,
                }}
                className={`w-100 d-flex justify-content-end align-items-end slide-content`}
              >
                {slide.hideTitleAndDesc || !slide.slideData ? null : (
                  <div
                    className={
                      "bg-light p-4 m-5 border-20 d-flex flex-column justify-content-between align-items-start"
                    }
                    style={{ width: "500px", height: "400px" }}
                  >
                    <div>
                      <h2 className={"my-3"}>{slide.slideData?.header}</h2>
                      <p className={"fz-14 my-3 "}>{slide.slideData?.text}</p>
                    </div>

                    <div className={"d-flex"}>
                      {slide.slideData?.button.left.link ? (
                        <Button
                          variant={"dark"}
                          className={"me-3"}
                          onClick={() =>
                            window.open(
                              slide.slideData?.button.left.link,
                              "_blank"
                            )
                          }
                        >
                          {slide.slideData?.button.left.text}
                        </Button>
                      ) : null}
                      {slide.slideData?.button.right.link ? (
                        <Button
                          variant={"outline-dark"}
                          onClick={() =>
                            window.open(
                              slide.slideData?.button.right.link,
                              "_blank"
                            )
                          }
                        >
                          {slide.slideData?.button.right.text}
                        </Button>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};
export default MainSlider;
