import React, { useRef, useState } from "react";
import { ReactComponent as Dots } from "../../svg/vertical-dots.svg";
import useOutsideClick from "../../customHooks/useOutsideClick";
import { Overlay, Popover } from "react-bootstrap";
import axios from "axios";
import getFile from "../../helpers/order/getOrderFile";
import { ReactComponent as Download } from "../../svg/download.svg";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

const FilesList = ({ files }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setShow(false);
  });

  return (
    <>
      <Dots
        ref={target}
        onClick={() => setShow(!show)}
        className={"cursor-pointer"}
      />

      <Overlay ref={ref} target={target.current} show={show}>
        <Popover id="popover-basic">
          <Popover.Header as="h3">{t("Others.files")}</Popover.Header>
          <Popover.Body>
            {files?.map((element) => (
              <div className={"p-0 w-100"}>
                <a
                  className="d-flex align-items-center my-1 no-underline"
                  href={URL.createObjectURL(element)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Download
                    className="icon-action svg-40 me-1"
                    style={{ minWidth: "40px" }}
                  />
                  {element.name}
                </a>
              </div>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};
export default FilesList;
