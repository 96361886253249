import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import CustomTableDescription from "./customTableDescription";
import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeProductFromCart } from "../../redux/cartSlice";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { currencyFormatter } from "../../helpers/currencyTools";
import { useTranslation } from "react-i18next";
import round from "../../helpers/round";
import CustomerProductNumber from "../cart/CustomerProductNumber";

const RemoveItem = ({ item, setTouched, selected, setSelected, ...rest }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const removeItem = () => {
    setLoading(true);
    dispatch(
      removeProductFromCart({
        productSapIndex: item?.product.sapIndex,
        uniqueKey: item.uniqueKey,
      })
    ).then(() => {
      setSelected(selected.filter((elem) => elem.uniqueKey != item.uniqueKey));
      handleClose();
      setLoading(false);
      setTouched(true);
    });
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Orders.delete_product")}</Modal.Title>
        </Modal.Header>
        {/*<Modal.Body>Czy jesteś pewien, że chcesz usunąć produkt? </Modal.Body>*/}
        <Modal.Footer>
          {loading ? (
            <div className={"d-flex justify-content-center w-100 "}>
              <Spinner animation={"border"} />
            </div>
          ) : (
            <>
              <Button variant="danger" onClick={removeItem}>
                {t("Orders.delete_btn")}
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                {t("Orders.cancel_btn")}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Button
        variant="link"
        className="rounded icon-action"
        onClick={() => setShow(true)}
        {...rest}
      >
        {" "}
        <img src="/img/delete-red.svg" alt="" />
      </Button>
    </>
  );
};

function ShoppingcartProduct({
  innerRef,
  innerRef2,
  innerRef3,
  innerRef4,
  item,
  onCheck,
  selected,
  setValuesList,
  setTouched,
  handleTouch,
  setSelected,
  valuesList,
}) {
  const userData = useSelector((state) => state.user.data);
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(
    Math.round(item.quantity * 1000) / 1000
  );
  const { grossWeight, volume } = item.product.technicalParameters.logisticData;
  const [weight, setWeight] = useState(round(grossWeight * item.quantity));
  const [orderVolume, setOrderVolume] = useState(
    round((volume * item.quantity) / 1000000)
  );
  useEffect(() => {
    setValuesList((state) =>
      state.map((elem) => {
        if (elem?.uniqueKey === item?.uniqueKey) {
          return {
            ...elem,
            val: quantity * item?.productPrice?.mainUnitPriceNet,
            weight: weight,
            volume: orderVolume,
          };
        } else {
          return elem;
        }
      })
    );
    handleTouch(item, quantity);
  }, [quantity]);
  function setCustomerProductNumber(event) {
    const { value } = event.target;

    setValuesList((state) =>
      state.map((elem) => {
        if (elem?.sapIndex === item?.product.sapIndex) {
          return {
            ...elem,
            customerOrderNumber: value,
          };
        } else {
          return elem;
        }
      })
    );
  }

  function weightVolume(quantity) {
    const weight = grossWeight * quantity;
    const v = (volume / 1000000) * quantity;
    setWeight(round(weight));

    setOrderVolume(round(v));
  }
  return (
    <div className="custom-table-row">
      <div className="custom-table-checkbox">
        <Form.Check
          onChange={(event) =>
            onCheck(
              event,
              item,
              quantity * item?.productPrice?.mainUnitPriceNet
            )
          }
          checked={selected.some(
            (element) => element.uniqueKey === item.uniqueKey
          )}
        />
      </div>
      <CustomTableDescription
        item={item}
        minOrder={`${item.unitConverter?.minOrderQuantity} ${item.unitConverter?.unitName}`}
        setCustomerProductNumber={setCustomerProductNumber}
        customerOrderNumber={
          valuesList.find((elem) => elem.sapIndex === item.product.sapIndex)
            ?.customerOrderNumber ?? ""
        }
      />

      <div className="custom-table-availabilty">
        {/* klasa available do dostępnego, zmienia kolor kolka */}
        {/* klasa later do dostepnego pozniej */}
        <div className={item.availableNow ? "available" : "later"}>
          {" "}
          {item.status}
        </div>
      </div>
      <div
        className="custom-table-price flex-column"
        ref={innerRef}
        data-title={t("SummaryTable.net")}
      >
        <div className="price">
          {`${currencyFormatter(
            item?.productPrice?.mainUnitPriceNet,
            userData.customerData.canSeeProductPrice
          )} ${item?.productPrice?.currency ?? ""} / ${
            item?.unitConverter?.shortUnitName ?? ""
          }`}
        </div>
        <RemoveItem
          item={item}
          setTouched={setTouched}
          selected={selected}
          setSelected={setSelected}
          style={{ position: "absolute", top: "-22px", right: "0" }}
          className="rounded icon-action d-flex d-lg-none"
        />
      </div>
      <div
        className="custom-table-counter flex-column gap-10"
        ref={innerRef2}
        data-title={t("SummaryTable.quantity")}
      >
        <Form.Group className={`counter flex-column`}>
          <div className={"d-flex border border-dark rounded"}>
            <Button
              variant="light"
              className="border-0"
              onClick={() => {
                if (
                  quantity >= item?.unitConverter?.multiple &&
                  quantity - item?.unitConverter?.multiple >=
                    item.unitConverter?.minOrderQuantity
                ) {
                  setQuantity((count) => {
                    let newVal = count - item?.unitConverter?.multiple;
                    const round = Math.round(newVal * 1000) / 1000;

                    weightVolume(round);
                    return round;
                  });
                }
              }}
            >
              <img src="/img/minus.svg" alt="" />
            </Button>{" "}
            <Form.Control
              value={quantity}
              size="sm"
              className="text-center"
              type={"number"}
              readOnly={true}
            />
            <Button
              variant="light"
              onClick={() =>
                setQuantity((count) => {
                  const newVal = count + item?.unitConverter?.multiple;
                  const round = Math.round(newVal * 1000) / 1000;

                  return round;
                })
              }
              className="border-0"
            >
              <img src="/img/plus.svg" alt="" />
            </Button>
          </div>
          <span className={"mt-2"}>
            {item.selectUnitConverter?.unit === item.unitConverter?.unit ? (
              <>{item.unitConverter?.shortUnitName}</>
            ) : (
              <>
                {quantity} {item.unitConverter?.shortUnitName} ={" "}
                {(
                  (quantity *
                    (item.unitConverter.counter /
                      item.unitConverter.denominator)) /
                  (item.selectUnitConverter.counter /
                    item.selectUnitConverter.denominator)
                ).toFixed(2)}{" "}
                {item.selectUnitConverter.shortUnitName}
              </>
            )}
          </span>
          {userData?.customerData.notSeeProductWeight ? null : (
            <span className={"mt-1"}>
              {t("Orders.weight")} {weight} kg
            </span>
          )}
          {userData?.customerData.notSeeProductVolume ? null : (
            <span className={"mt-1"}>
              {t("Orders.volume")} {orderVolume} m<sup>3</sup>
            </span>
          )}
        </Form.Group>
      </div>

      <div
        className="custom-table-sum"
        ref={innerRef3}
        data-title={t("SummaryTable.net_total")}
      >
        {currencyFormatter(
          quantity * item?.productPrice?.mainUnitPriceNet,
          userData.customerData.canSeeProductPrice
        )}
        {item?.productPrice?.currency}
      </div>
      <div className="custom-table-delete d-none d-lg-flex" ref={innerRef4}>
        <RemoveItem
          item={item}
          setTouched={setTouched}
          selected={selected}
          setSelected={setSelected}
        />
      </div>
    </div>
  );
}

export default ShoppingcartProduct;
