import {Badge, Spinner} from "react-bootstrap";
import Price from "../price";
import {useSelector} from "react-redux";

function PriceInPill({className = "", priceList, priceLoading, ...rest}) {
  const userData = useSelector((state) => state.user.data);
  return (
      priceLoading ? <Spinner animation={'border'} size={'sm'}/> :
        <span className={`fw-600 text-pill p-2 meter-price ${className}`} {...rest}>
            {  userData?.customerData.canSeeProductPrice ? (
              `${priceList?.minPrice === priceList?.maxPrice ? priceList?.minPrice.toFixed(2) : priceList?.minPrice.toFixed(2) + "-" + priceList?.maxPrice.toFixed(2)} ${priceList?.currency} / ${priceList?.conditionUnitTrans}`
            ) : (
              `- ${priceList?.currency} / ${priceList?.conditionUnitTrans}`
            )}

        </span>

    );
};
export default PriceInPill;