import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoginLeftColumn from "../components/loginLeftColumn";
import {useForm} from "react-hook-form";
import axios from "axios";
import {setToken} from "../redux/userSlice";
import { useTranslation } from "react-i18next";
import '../i18n';

const SetPassword = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {register, handleSubmit, getValues, formState:{ errors }} = useForm();
    const [loading, setLoading] = useState(false)
    const [apiError, setApiError] = useState('')
    const [showError, setShowError] = useState(false)
    const[requirements, setRequirements] = useState(null)
    const {token} = useParams()

    const onSubmit = data => {

        console.log(token)

        setLoading(true)
        axios.post(
            "/user/set-password",
            JSON.stringify({
                token: token,
                ...data
            }),
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        ).then(response => {
            if (response.status === 200) {

                navigate("/login-password-changed");

            }
            setLoading(false)
        }).catch(error => {


            setLoading(false)
            setApiError(error?.response?.data?.message?.content)

            setShowError(true)

        })
    };
    const getPassRequirements=()=>{
        axios.get('/user/password-requirements').then(response => setRequirements(response.data.message))
    }
    useEffect(getPassRequirements,[])


    return (
        <>
            <Container fluid className="main-container login">
                <Row className="my-auto login-wrapper">
                    <LoginLeftColumn />
                    <Col
                        xs="12"
                        lg="6"
                        xl="7"
                        className="d-flex justify-content-center flex-column align-items-center text-center"
                    >
                        <div>
                            <h2>{t("Welcome.set_new_pass")}</h2>
                            <p>{requirements?.content ?? ""}</p>

                            <Form
                                onSubmit={handleSubmit(onSubmit)}

                            >
                                <Form.Group className="mt-5 mb-4 pb-1 user password" controlId="user-email">
                                    <Form.Control
                                        type="password"
                                        placeholder={t("Welcome.new_pass")}
                                        className=""
                                        {...register("password", { required: true, } )}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-5 user password" controlId="user-email">
                                    <Form.Control
                                        type="password"
                                        placeholder={t("Welcome.repeat_pass")}
                                        className="mb-4"
                                        {...register("confirmPassword",{ validate: value => value === getValues("password") || t("Welcome.compatibility_pass"), required: true, })}
                                    />
                                </Form.Group>
                                <Button variant="success" type="submit" className="w-100 mb-3" disabled={loading} >

                                    {loading ? <Spinner size={"sm"} animation="border"/> : t("Welcome.save")}

                                </Button>
                                <Alert

                                    variant={"danger"}
                                    show={errors?.confirmPassword?.message ? true :false}
                                >
                                    {/*{message}*/}
                                    {errors?.confirmPassword?.message}

                                </Alert>
                                <Alert

                                    variant={"danger"}
                                    show={showError}
                                >

                                    {apiError}

                                </Alert>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default SetPassword;
