import {useState} from "react";
import offerProductPriceAndUnit from "../components/offer/offerProductPriceAndUnit";

export default function useOffer() {
    const [products, setProducts] = useState([])
    const [quantitySum, setQuantitySum] = useState(0)
    const [extraQuantitySum, setExtraQuantitySum] = useState(0)
    const [valueSum, setValueSum] = useState(0)
    const handleQuantity = (quantity, sapIndex, isArchive = false) => {
        setProducts(state => {
            const newState = state.map((item) => {
                const sapIndex1 = item.product?.sapIndex
                if (sapIndex1 && sapIndex1 === sapIndex && !item.isExtra) {
                    const {value, cartQuantity} = offerProductPriceAndUnit(quantity, item, isArchive)
                    return {
                        ...item,
                        quantity: quantity,
                        value: value,
                        cartQuantity: cartQuantity
                    }
                } else {
                    return item
                }
            })
            setQuantitySum(newState.reduce((accumulator, currentValue) => (accumulator + (currentValue.isExtra ? 0 : currentValue.quantity)), 0))
            setValueSum(newState.reduce((accumulator, currentValue) => (accumulator + currentValue.value), 0))
            return newState;
        })
    }
    const handleExtraQuantity = (quantity, sapIndex, isArchive = false) => {
        setProducts(state => {
            const newState = state.map((item) => {
                if (item.product?.sapIndex === sapIndex && !item.isCustom) {
                    const {cartQuantity} = offerProductPriceAndUnit(quantity, item, isArchive)

                    return {
                        ...item,
                        extraQty: quantity,
                        extraCartQuantity: cartQuantity
                    }
                } else {
                    return item
                }
            })
            setExtraQuantitySum(newState.reduce((accumulator, currentValue) => (accumulator + currentValue.extraQty), 0))
            return newState;
        })
    }
    const handleProducts = (data) => {
        let p = []
        const isArchive = data.isArchive
        p = data.products.map(item => {

            return {
                quantity: isArchive ? item.chosenProductQuantity : 0,
                extraQty: isArchive ? item.chosenExtraProductQuantity : 0,
                maxQty: data?.numberOfProduct,
                extraMaxQty: parseInt(data.promotionDetails.numberOfProduct),
                isExtra: false,
                isCustom: false,
                value: 0,
                isArchive: isArchive,
                ...item
            }
        })
        if (data?.promotionType === "second") {
            const {numberOfProduct, product, unitOfProduct} = data.promotionDetails
            const item = {
                ...product,
                availableUnit: {
                    ...product.availableUnit,
                    unit: unitOfProduct
                }
            }
            const {cartQuantity} = offerProductPriceAndUnit(parseFloat(numberOfProduct), item, isArchive)

            p.push(
                {
                    ...product,
                    isExtra: true,
                    isCustom: false,
                    extraQty: parseInt(numberOfProduct),
                    quantity: 0,
                    value: cartQuantity ? cartQuantity * 0.01 : 0.01,
                    isArchive: isArchive,
                    unit: data.promotionDetails.unitOfProduct
                }
            )
        }
        if (data?.promotionType === "fourth") {
            p.push(
                {
                    ...data.promotionDetails,
                    isCustom: true,
                    isExtra: false,
                    quantity: 0,
                    extraQty: 0,
                    value: 0.01,
                    isArchive: isArchive,
                }
            )
        }

        setProducts(p)
        if (isArchive) {
            data.products.forEach(item => {
                handleQuantity(item.chosenProductQuantity, item.product.sapIndex, true)
                handleExtraQuantity(item.chosenExtraProductQuantity, item.product.sapIndex, true)
            })
        }
    }


    return {
        sumQuantity: 0,
        handleQuantity: handleQuantity,
        setProducts: handleProducts,
        products: products,
        quantitySum: quantitySum,
        extraQuantitySum: extraQuantitySum,
        handleExtraQuantity: handleExtraQuantity,
        valueSum: valueSum
    }
}