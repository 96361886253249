import { Link, useLocation, useSearchParams } from "react-router-dom";
import SideBarMainLink from "./sidebarMainLink";
import FooterElements from "../footerElements";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/userSlice";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionContext,
  useAccordionButton,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ReactComponent as Expand } from "../../svg/expand_more.svg";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import i18n from "i18next";
import { useQuery } from "@tanstack/react-query";

const SideBar = ({ sidebarToggle }) => {
  let [searchParams] = useSearchParams();
  const category = searchParams.get("filters[category][]");
  const subCategory = searchParams.get("filters[subCategory][]");
  const userData = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const location = useLocation();

  const categoriesQuery = useQuery({
    queryKey: ["category", i18next.language],
    queryFn: () =>
      axios.get("/category").then((response) => {
        console.log("sideBar");
        return response.data.items;
      }),
    staleTime: 1000 * 60 * 60,
  });

  const CustomToggle = ({ eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey);

    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <Button
        className="custom-toggle-button"
        size={"sm"}
        onClick={decoratedOnClick}
      >
        {isCurrentEventKey ? (
          <Expand style={{ transform: "rotate(180deg)" }} />
        ) : (
          <Expand />
        )}
      </Button>
    );
  };

  const { t } = useTranslation();

  return (
    <>
      {" "}
      <PerfectScrollbar>
        <div className="d-flex flex-column justify-content-between ps-50 pe-40 sidebar-container">
          <div className="upper-sidebar mt-5">
            <div>
              <Link to="/">
                <img
                  className={"logo"}
                  src={`/img/${
                    window.location.host === "b2bewifoam.de" ||
                    window.location.host ===
                      "papaya-malasada-52b1cc.netlify.app"
                      ? "ewifoam"
                      : "logo"
                  }.svg`}
                  alt=" "
                />
              </Link>
            </div>
            <SideBarMainLink
              title={t("Menu.start")}
              link="/home"
              className={`home ${
                location?.pathname === `/home` ? "active" : ""
              }`}
              sidebarToggle={sidebarToggle}
            />

            <SideBarMainLink
              title={t("Menu.products")}
              link="/products-list"
              className={` ${
                location?.pathname === `/products-list` ? "active" : ""
              }`}
              sidebarToggle={sidebarToggle}
            />

            <div className="sidebar-links">
              <Accordion>
                {categoriesQuery.data?.map((item, index) => {
                  const isActive =
                    category === `${item.id}` ||
                    item.subCategories?.some(
                      (element) => `${element.id}` === subCategory
                    );
                  return (
                    <div
                      key={index}
                      className="justify-content-start flex-grow-1 pe-3 flex-column position-relative"
                    >
                      {item.subCategories?.length ? (
                        <CustomToggle eventKey={index} />
                      ) : null}
                      <Link
                        to={`/marketing-list?filters[category][]=${item.id}`}
                        key={index}
                        className={`lh-24 mb-1 ${isActive ? "active" : ""}`}
                        onClick={sidebarToggle}
                      >
                        {item.name}
                      </Link>

                      {item.subCategories?.length ? (
                        <Accordion.Collapse
                          eventKey={index}
                          aria-labelledby={`nav-${item.id}`}
                        >
                          <div className={"d-flex flex-column fw-normal"}>
                            {item.subCategories?.map((subItem, index) => (
                              <Link
                                onClick={sidebarToggle}
                                key={index}
                                to={`/marketing-list?filters[category][]=${item.id}&filters[subCategory][]=${subItem.id}`}
                                className={`${
                                  subCategory === `${subItem.id}`
                                    ? "active"
                                    : ""
                                }`}
                              >
                                {subItem.name}
                              </Link>
                            ))}
                          </div>
                        </Accordion.Collapse>
                      ) : null}
                    </div>
                  );
                })}
              </Accordion>
            </div>
          </div>
          <div className="lower-sidebar">
            <SideBarMainLink
              title={t("Menu.orders")}
              link="/orders"
              className={`link-arrow ${
                location?.pathname === `/orders` ? "active" : ""
              }`}
              sidebarToggle={sidebarToggle}
              counter={userData?.orderCount ?? ""}
            />
            <SideBarMainLink
              title={t("Menu.offers")}
              link="/offers"
              className={`link-arrow ${
                location?.pathname === `/offers` ? "active" : ""
              }`}
              counter={userData?.offerCount ?? ""}
              sidebarToggle={sidebarToggle}
            />
            <SideBarMainLink
              title={t("Menu.complaints")}
              link="/complaints"
              className={`link-arrow ${
                location?.pathname === `/complaints` ? "active" : ""
              }`}
              sidebarToggle={sidebarToggle}
            />
            {userData?.customerData?.accessToInvoice ? (
              <SideBarMainLink
                title={t("Menu.invoices")}
                link="/invoices"
                className={`link-arrow ${
                  location?.pathname === `/invoices` ? "active" : ""
                }`}
                sidebarToggle={sidebarToggle}
              />
            ) : (
              <></>
            )}
            <Link
              onClick={sidebarToggle}
              to="/client-profile"
              className="account login icon-left icon-right pill h-40 d-flex align-items-center w-100 fw-bold"
            >
              <span>
                {t("Menu.account")}
                {/*<small>*/}
                {/*    {userData?.customerData?.account?.companyName}*/}
                {/*</small>*/}
              </span>
            </Link>
            <Button
              className={"mt-3"}
              size={"sm"}
              variant={"link"}
              onClick={() => dispatch(logOut())}
            >
              {t("Menu.logout")}
            </Button>
            <FooterElements />
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};
export default SideBar;
