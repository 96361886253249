const offerProductPriceAndUnit = (quantity, item, isArchive = false) => {

    console.log(quantity, isArchive)


    const defaultUnit = item.unitConverters[item.availableUnit.defaultUnit]
    const baseUnit = item.unitConverters[item.baseUnit.unit]
    const selectedUnit = item.unitConverters[item.availableUnit.unit]
    const inBaseUnit = quantity * (selectedUnit?.counter / selectedUnit?.denominator);
    const inDefaultUnit = defaultUnit?.counter / defaultUnit?.denominator;
    const cartQuantity = isArchive ? quantity : Math.ceil(((inBaseUnit / inDefaultUnit) / defaultUnit?.multiple)* defaultUnit?.multiple) ;
    const baseQuantity = Number.parseFloat(cartQuantity * (defaultUnit?.counter / defaultUnit?.denominator))


    function price(){
        const prices = item.promotionPriceList ? item.promotionPriceList?.prices?.filter(item => item.amount !== 0) : item.priceList?.prices?.filter(item => item.amount !== 0)
        if (!prices?.length) {
            return 0
        }
        const lastPrice = prices[prices?.length - 1]
        if (lastPrice?.scaleQty < quantity) {
            return lastPrice.amount

        }

        return prices.find(item => item.scaleQty >= quantity)?.amount
    }
    const p = price()
    return {
        value:cartQuantity * p,
        baseUnit: baseUnit,
        cartQuantity: cartQuantity,
        baseQuantity: inBaseUnit
    }


};
export default offerProductPriceAndUnit;
