import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import handleCartOffers from "../helpers/handleCartOffers";
import i18next from "i18next";

// export const checkMinima = createAsyncThunk()
export const fetchCart = createAsyncThunk(
  "cart/fetchCart",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const { data, logisticData } = state.user;

    const response = await axios.get("/cart").then(async function (res) {
      if (
        !data.customerData.canSeeProductPrice &&
        logisticData.orderBlockIfNotMet
      ) {
        let offers = [];
        let products = [];
        res.data.items[0].cartProducts.forEach((element) => {
          if (element.isFromOffer) {
            if (!offers.some((item) => item.offerId === element.offerId)) {
              offers.push({ offerId: element.offerId });
            }
          } else {
            products.push({
              productSapIndex: element.product.sapIndex,
              quantity: element.quantity,
              unit: element.unit,
              uniqueKey: element.uniqueKey,
              customerOrderNumber: null,
            });
          }
        });

        const d = {
          products,
          isOneTimeAddress: false,
          offers,
        };
        await axios.post("/order/check-minima", d).then((r) => {
          thunkAPI.dispatch(setOrderingBlock(!r.data.content));
        });
        return res;
      } else {
        return res;
      }
    });

    return response.data;
  }
);
export const getCartCount = createAsyncThunk(
  "cart/getCartCount",
  async (_, thunkAPI) => {
    const response = await axios.get("/cart/count");
    return response.data;
  }
);

export const updateCart = createAsyncThunk(
  "cart/updateCart",
  async (products = Array) => {
    const response = await axios.put("/cart", {
      products: products,
    });
    return response.data;
  }
);
export const removeProductFromCart = createAsyncThunk(
  "cart/removeProduct",
  async (product, thunkAPI) => {
    const data = {
      products: [
        {
          ...product,
        },
      ],
    };
    const response = await axios.delete("/cart", {
      data: JSON.stringify(data),
    });
    await thunkAPI.dispatch(getCartCount());
    const ret = {
      data: await response.data,
      product: product,
    };
    return ret;
  }
);
export const removeOfferFromCart = createAsyncThunk(
  "cart/removeOffer",
  async (offerId, thunkAPI) => {
    const response = await axios.delete(`/offer/${offerId}/cart`);
    await thunkAPI.dispatch(getCartCount());

    return {
      id: offerId,
      response: await response,
    };
  }
);
export const addToCart = createAsyncThunk(
  "cart/addToCart",
  async (product, thunkAPI) => {
    console.log(product.selectedUnit);

    const data = {
      productSapIndex: product.itemData.sapIndex,
      unit: product.unit,
      selectUnit: product.selectedUnit,
      quantity: product.quantity,
    };
    const response = await axios
      .post("/cart", JSON.stringify(data))
      .catch((error) => {
        return error.response.status;
      });
    await thunkAPI.dispatch(getCartCount());
    return await response;
  }
);
export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cart: [],
    offers: [],
    errors: [],
    loadingCart: false,
    adding: false,
    showCartAlert: false,
    cartAlertMessage: "",
    cartCount: 0,
    blockOrdering: false,
    validPrice: true,
    fileName: "",
    isSomeAvailable: true,
    isSomeUnavailable: false,
  },
  reducers: {
    hideCartAlert(state) {
      state.showCartAlert = false;
    },
    setOrderingBlock(state, action) {
      state.blockOrdering = action.payload;
    },
    clearErrors(state) {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCartCount.fulfilled, (state, { payload }) => {
      state.cartCount = payload.totalCount;
    });
    builder.addCase(updateCart.pending, (state) => {
      state.loadingCart = true;
    });
    builder.addCase(updateCart.fulfilled, (state) => {
      state.loadingCart = false;
    });
    builder.addCase(fetchCart.pending, (state) => {
      state.loadingCart = true;
    });
    builder.addCase(fetchCart.fulfilled, (state, { payload }) => {
      state.offers = handleCartOffers(payload.items[0].cartProducts);
      state.cart = payload.items[0].cartProducts.filter(
        (element) => !element.isFromOffer
      );
      state.validPrice = payload.items[0].validPrice;
      state.errors = payload.items[0].errors;
      state.fileName = payload.items[0].fileName;
      state.isSomeAvailable = payload.items[0].cartProducts.some(
        (item) => item.availableNow === true
      );
      state.isSomeUnavailable = payload.items[0].cartProducts.some(
        (item) => item.availableNow === false
      );
      state.loadingCart = false;
    });
    builder.addCase(fetchCart.rejected, (state, action) => {
      state.loadingCart = false;
      // state.error = action.error.message;
    });
    builder.addCase(removeProductFromCart.fulfilled, (state, { payload }) => {
      state.cart = state.cart?.filter(
        (element) => element.uniqueKey !== payload.product.uniqueKey
      );
    });
    builder.addCase(addToCart.pending, (state) => {
      state.adding = true;
    });
    builder.addCase(addToCart.fulfilled, (state, { payload }) => {
      if (payload === 410) {
        return;
      }
      const { t } = i18next;
      state.showCartAlert = true;
      state.cartAlertMessage = t("ProductPage.added");
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      state.adding = false;
      // state.error = action.error.message;
    });
    builder.addCase(removeOfferFromCart.fulfilled, (state, { payload }) => {
      state.offers = state.offers.filter(
        (offer) => offer[0].offerId !== payload.id
      );
    });
  },
});

// Action creators are generated for each case reducer function
export const { hideCartAlert, setOrderingBlock, clearErrors } =
  cartSlice.actions;

export default cartSlice.reducer;
