import { Link } from "react-router-dom";

function SmallSwiperSlide({ sliderImage, sliderProduct, sliderPrice, id, imgClass = "" }) {
  return (
    <>
      <Link to={"/product/"+id}>
        <img

          src={sliderImage}
          alt=""
          style={{
            height: "170px",
            objectFit: "cover",
            objectPosition: "center",
          }}
          className={imgClass}
        />
      </Link>
      <Link className="fw-bold fz-14 text-start w-100 mt-2  unstyled-a" to={"/product/"+id}>
        {sliderProduct}
      </Link>
      <div className="mt-2 px-2 py-1 text-pill text-start fz-18 fw-500">
        {sliderPrice}
      </div>
    </>
  );
}
export default SmallSwiperSlide;
