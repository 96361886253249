import axios from "axios";
import i18next from "i18next";
import React, { useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import XLSX from "sheetjs-style";
import { clearErrors } from "../../redux/cartSlice";

function CartDraftErrors({ errors, fileName }) {
  const { t } = i18next;
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function removeErrors() {
    setLoading(true);
    axios
      .post("/cart/remove-errors")
      .then(() => {
        dispatch(clearErrors());

        handleClose();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function xlxsx() {
    const wb = XLSX.utils.book_new();
    const data = errors.map((item) => [
      `${item.eanNumber}`,
      `${item.sapIndex}`,
      `${item.name}`,
      `${item.quantity}`,
      `${item.unit}`,
      `${item.error}`,
    ]);
    const ws = XLSX.utils.aoa_to_sheet([
      ["EAN", "SAP Index", "Nazwa", "Liczba j.", "Jedn.", "Błąd"],
      ...data,
    ]);

    XLSX.utils.book_append_sheet(wb, ws, "raport");

    XLSX.writeFile(wb, `raport.xlsx`);
  }
  return (
    <>
      {" "}
      <div className="mb-4">
        <Button variant="success" onClick={handleShow}>
          {t("CartDraft.seeRaport")}
        </Button>
        <p>
          {t("CartDraft.errorsNumber")}: {errors.length}
        </p>
      </div>
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {t("CartDraft.title")} {fileName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{t("CartDraft.duringImport")}:</span>
          <Table striped bordered hover size="sm" className="mt-2">
            <thead>
              <tr>
                <th>EAN</th>
                <th>SAP Index</th>
                <th>{t("ProductSpec.name")}</th>
                <th>{t("CartDraft.count")}</th>
                <th>{t("CartDraft.unit")}</th>
                <th>{t("CartDraft.error")}</th>
              </tr>
            </thead>
            <tbody>
              {errors?.map((item, i) => (
                <tr key={i}>
                  <td>{item.eanNumber}</td>
                  <td>{item.sapIndex}</td>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.unit}</td>
                  <td>{item.error}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="outline" onClick={xlxsx}>
            {t("CartDraft.generateXlsx")}
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={removeErrors} disabled={loading}>
            {loading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              <>{t("CartDraft.dontShow")}</>
            )}
          </Button>
          <Button variant="success" onClick={handleClose}>
            {t("Pop.close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CartDraftErrors;
