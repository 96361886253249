import Button from "react-bootstrap/Button";

function ChoosenFilter({label = "", value = "", onClick}) {
  return (
    <>
      <div className="choosen-filter fz-12 m-1">
          {label}:&nbsp;<b>{value}</b>
        <Button variant="link" className="pe-0 ps-2" onClick={onClick}>
        <img src="/img/close.svg" alt="" />
        </Button>
      </div>
    </>
  );
}
export default ChoosenFilter;
