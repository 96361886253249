import Modal from "react-bootstrap/Modal";
import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import {
  Alert,
  Col,
  FormControl,
  FormGroup,
  FormSelect,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
} from "react-bootstrap";
import FloatCounter from "../util/floatCounter";
import { useSelector } from "react-redux";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { isMobile } from "react-device-detect";
import { ReactComponent as Delete } from "../../svg/smallDelete.svg";
import ItemSearchSelect from "./itemSearchSelect";
import { useTranslation } from "react-i18next";
import { selectOptions } from "@testing-library/user-event/dist/select-options";
import filesList from "./filesList";

const AddProduct = ({
  defaultProduct,
  onAdd,
  isFromScan = false,
  backToScan,
  buttonText = "",
  ...rest
}) => {
  const { options, products } = useSelector((state) => state.reclamation);
  const { t } = useTranslation();

  const [product, setProduct] = useState(null);
  const [reason, setReason] = useState("");
  const [expectancy, setExpectancy] = useState("");
  const [show, setShow] = useState(false);
  const [useCamera, setUseCamera] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [additionalData, setAdditionalData] = useState(null);
  const [isFileRequired, setIsFileRequired] = useState(null);
  const [isLimitedQuantity, setIsLimitedQuantity] = useState(null);
  const [files, setFiles] = useState([]);
  const [isFromOutside, setIsFromOutside] = useState(false);
  const [comment, setComment] = useState(null);
  const [reasonInvalid, setReasonInvalid] = useState(false);
  const [expectancyInvalid, setExpectancyInvalid] = useState(false);
  const ref = useRef();
  const acceptableExtensions = ["jpg", "pdf", "png"];
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const filesInput = (event) => {
    setShowAlert(false);
    setAlertMsg("");
    let list = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();
      if (acceptableExtensions.some((element) => element === fileExtension)) {
        list.push(file);
      } else {
        setAlertMsg(
          `${t("Add.file")} ${fileName} ${t("Add.wrong_extension")}.`
        );
        setShowAlert(true);
      }
    }
    setFiles((state) => [...state, ...list]);
  };
  const onHide = () => {
    setShow(false);
    setQuantity(0);
    setFiles([]);
    setComment(null);
  };

  async function url2File(url) {
    const blob = await (await fetch(url)).blob();
    const file = await new File([blob], `image${files?.length}.jpg`, {
      type: blob.type,
    });
    await setFiles((state) => [...state, file]);
  }

  function handleTakePhoto(dataUri) {
    // Do stuff with the photo...
    url2File(dataUri);
  }

  const handleAdd = () => {
    onAdd({
      ...product,
      quantity: quantity,
      reason: reason,
      reasonName: options?.find((item) => item.id === reason).name,
      expectancy: expectancy,
      expectancyName: options
        ?.find((item) => item.id === reason)
        ?.expectancy.find((item) => item.id === expectancy).name,
      comment: comment,
      files: files,
    });

    if (isFromScan) {
      backToScan();
    }

    onHide();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        onEnter={() => {
          setProduct(defaultProduct);
        }}
        onExit={() => {
          setExpectancy("");
          setReason("");
        }}
      >
        <ModalHeader>
          {t("Pop.add")} {""}
          {product?.name ?? t("Pop.product")} {t("ProductPage.to_complaint")}
        </ModalHeader>
        <ModalBody>
          {product ? (
            <Row>
              <Col md={12}>
                <FormGroup>
                  <FormSelect
                    onChange={(event) => {
                      const selectedOption = options.find(
                        (item) => item.id === parseInt(event.target.value)
                      );
                      setAdditionalData(selectedOption?.additionalData);
                      setIsFileRequired(selectedOption?.isFileRequired);
                      setIsLimitedQuantity(
                        selectedOption?.isLimitedNumberProduct
                      );
                      setReason(parseInt(event.target.value));
                      setQuantity(0);
                      setReasonInvalid(false);
                      if (selectedOption?.isFileRequired) {
                        setAlertMsg(t("Add.minFile"));
                        setShowAlert(true);
                      }
                    }}
                    className={" p-2"}
                    value={reason}
                    disabled={isFromOutside}
                    isInvalid={reasonInvalid}
                  >
                    <option value="">{t("Add.reason")}</option>
                    {options?.map((item, index) => (
                      <option value={item.id} key={index}>
                        {item.name}
                      </option>
                    ))}
                  </FormSelect>
                  <Form.Control.Feedback type="invalid">
                    {t("Add.add_reason")}
                  </Form.Control.Feedback>
                </FormGroup>

                <div
                  className={"pt-1"}
                  dangerouslySetInnerHTML={{ __html: additionalData }}
                ></div>

                <FormGroup>
                  <FormSelect
                    onChange={(event) => {
                      setExpectancy(parseInt(event.target.value));
                      setExpectancyInvalid(false);
                    }}
                    className={" mt-3 p-2"}
                    value={expectancy}
                    isInvalid={expectancyInvalid}
                  >
                    <option value="">{t("Add.expectations")}</option>
                    {options
                      ?.find((item) => item.id === reason)
                      ?.expectancy.map((element, index) => (
                        <option value={element.id} key={index}>
                          {element.name}
                        </option>
                      ))}
                  </FormSelect>
                  <Form.Control.Feedback type="invalid">
                    {t("Add.choose_expectations")}
                  </Form.Control.Feedback>
                </FormGroup>
                <FloatCounter
                  max={isLimitedQuantity ? product.quantity : null}
                  setQuantity={setQuantity}
                  quantity={quantity}
                  className={"w-100 counter-border mt-4 "}
                  disabled={reason === ""}
                />
                <div className={"text-center mt-1"}>
                  {product.shortUnitName}
                </div>
                <p className="mt-3 mb-1 ms-1">{`${
                  comment?.length ?? 0
                } / 500`}</p>
                <FormControl
                  placeholder={t("Pop.comment")}
                  className={" p-2 mt-1"}
                  as="textarea"
                  maxLength={500}
                  value={comment}
                  onInput={(e) => setComment(e.target.value)}
                />
                <div className="d-flex flex-column align-items-start my-3">
                  <small className={"w-100"}>{t("Add.img")}</small>

                  <div
                    className={
                      "d-flex justify-content-between align-items-center mt-3 w-100 "
                    }
                  >
                    <Button
                      onClick={() => ref.current?.click()}
                      variant={"info"}
                    >
                      {t("Add.add_files")}
                    </Button>

                    <FormControl
                      multiple={true}
                      ref={ref}
                      type={"file"}
                      onInput={filesInput}
                      style={{ visibility: "hidden", display: "none" }}
                    />
                    {isMobile ? (
                      <Button onClick={() => setUseCamera((state) => !state)}>
                        {t("Add.take_photo")}
                      </Button>
                    ) : null}
                  </div>
                  <Alert
                    variant={"danger"}
                    className={"mt-3 py-2"}
                    show={showAlert}
                  >
                    {alertMsg}
                  </Alert>
                </div>

                {useCamera ? (
                  <Camera
                    onTakePhoto={(dataUri) => {
                      handleTakePhoto(dataUri);
                    }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    imageCompression={0.75}
                    imageType={IMAGE_TYPES.JPG}
                  />
                ) : null}

                {files
                  ? files.map((file, index) => (
                      <div key={index} className={"mt-1"}>
                        {file.name}
                        <Button
                          size={"sm"}
                          variant={"link"}
                          onClick={() => {
                            setFiles((state) =>
                              state.filter((item, i) => i !== index)
                            );
                          }}
                        >
                          <Delete />
                        </Button>
                      </div>
                    ))
                  : null}
              </Col>
            </Row>
          ) : (
            <ItemSearchSelect
              onSelect={(v) => {
                setIsFromOutside(true);
                const surplusReason = options.find(
                  (element) => element.isSurplus
                );
                setReason(surplusReason?.id);
                setProduct(v);
              }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant={"secondary"} onClick={onHide}>
            {t("Pop.cancel")}
          </Button>
          <Button
            disabled={quantity <= 0 || (isFileRequired && files?.length <= 0)}
            variant={"success"}
            onClick={() => {
              if (reason === "") {
                setReasonInvalid(true);
              } else if (expectancy === "") {
                setExpectancyInvalid(true);
              } else {
                handleAdd();
              }
            }}
          >
            {t("Pop.add")}
          </Button>
        </ModalFooter>
      </Modal>
      <Button onClick={() => setShow(true)} variant={"success"} {...rest}>
        {buttonText}
      </Button>
    </>
  );
};
export default AddProduct;
