import { useEffect, useRef, useState } from "react";
import { ReactComponent as Chevron } from "../../svg/keyboard_arrow_down_black_24dp.svg";
import useOutsideClick from "../../customHooks/useOutsideClick";

const CheckboxSelect = ({
  options = [],
  onChange,
  value,
  req,
  className,
  disabled,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOptionsOpen(false);
  });

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  function handleSelect(event) {
    const { value, checked } = event.target;

    if (checked) {
      setSelectedOptions((state) => [...state, value]);
    } else {
      setSelectedOptions((state) => {
        const newState = state.filter((item) => item !== value);

        return newState;
      });
    }
  }
  return (
    <div className={"checkbox-select " + className} ref={ref}>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        className="rounded-pill"
        onClick={toggleOptions}
        disabled={disabled}
        aria-disabled={disabled}
        style={req ? { border: "2px solid #ad003e", borderRadius: "5px" } : {}}
      >
        <span className={"w-100 overflow-hidden "}>
          {selectedOptions.slice(0, 2).map((item, index) => `${item}, `)}
          {selectedOptions.length > 2 ? "..." : ""}
        </span>
        <Chevron
          style={{ transform: `rotate(${isOptionsOpen ? "180" : "0"}deg)` }}
        />
      </button>
      <ul
        className={`options ${isOptionsOpen ? "show" : ""}`}
        role="listbox"
        tabIndex={-1}
      >
        {options?.map((option, index) => (
          <li
            key={index}
            id={option}
            role="option"
            aria-selected={selectedOptions.some(
              (item) => item === option.value
            )}
            tabIndex={0}
          >
            <div className="">
              <label
                title=""
                for={option.value}
                className="cursor-pointer w-100"
              >
                <input
                  type="checkbox"
                  id={option.value}
                  className="me-1"
                  value={option.value}
                  onChange={handleSelect}
                  checked={selectedOptions.some(
                    (item) => item === option.value
                  )}
                />

                {option.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CheckboxSelect;
