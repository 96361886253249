import { FormSelect } from "react-bootstrap";

function UnitSelect({ data, value, onSelect, ...rest }) {
  return (
    <div className="h-40 select-product-wrapper">
      <FormSelect
        className={"h-100"}
        value={value}
        onChange={onSelect}
        {...rest}
      >
        {data?.map((item, index) => (
          <option value={item.unit} key={index} className={"text-center"}>
            {item.unitName}
          </option>
        ))}
      </FormSelect>
    </div>
  );
}

export default UnitSelect;
