import HeaderBar from "../components/headerBar/headerBar";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import ScanditScanner from "../components/snaditScanner/scanditScanner";
import { useTranslation } from "react-i18next";


export default function Scanner() {
const { t } = useTranslation();  
    
    const navigate = useNavigate()
    const [pause, setPause] = useState(false);
    function getProductId(ean){
        setPause(true)
        axios.get(`/product/${ean}`).then(({data})=> {
            setPause(false)
            if (data.id){
                navigate("/product/" + data.id)
            }else{

            }
        }).catch(()=>{
            setPause(false)
        })
    }

    return (
      <>
        <HeaderBar header={t("Others.scaner")}  className="ps-34"></HeaderBar>

        <ScanditScanner onResult={getProductId} />
      </>
    );
}