export default function (data) {
    const products = data.filter(item => item.isFromOffer)
    let uniqueOffersIds = []

    products.forEach(item => {
        const {offerId} = item;
        if (!uniqueOffersIds.some(element => element === offerId)) {
            uniqueOffersIds.push(offerId)
        }
    })
    let offers = []
    uniqueOffersIds.forEach(id => {
        const offer = products.filter(element => element.offerId === id)
        offers.push(offer)
    })

    return offers
}