import React, {useEffect, useState} from 'react';
import axios from "axios";
import CustomSelect from "../customSelect/customSelect";
import {Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function BuyerSelect({setValue, watch}) {
    const [data, setData] = useState(null)
    const { t } = useTranslation();

    function getList() {
        axios.get('order/buyer').then(response => {
            setData(response.data.items)
        })
    }

    useEffect(() => {
        getList()
    }, []);
    return (
        <Row className={'mb-3'}>
            <Col xs={10} sm={8} lg={6} xxl={5} >
                <Form.Group>
                    <Form.Label>{t('Cashbox.orderForBuyer')}</Form.Label>
                    <Form.Control as="select" size={'sm'}
                                  value={watch('forBuyer')}
                                  className={'border-black-80 ps-2'}
                                  onChange={(event) => {
                                      const {value} = event.target
                                      setValue('byPayer', value !== '')
                                      setValue('forBuyer', value)


                                  }}
                    >
                        <option value={''}>-</option>

                        {
                            data?.map((item, index) => (
                                <option value={item.sapIndex}
                                        key={index}>{`${item.name1}, ${item.street} ${item.postCode} ${item.city}, ${item.country}`}</option>
                            ))
                        }
                    </Form.Control>
                </Form.Group>
            </Col>
        </Row>

    );
}

export default BuyerSelect;