import Button from "react-bootstrap/Button";
import {removeOfferFromCart} from "../../redux/cartSlice";
import {React, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {useDispatch} from "react-redux";

export function RemoveOffer({offerId}) {
    const [show, setShow] = useState(false)
    const dispatch = useDispatch()
const { t } = useTranslation();  


    return (
        <>
            <Modal show={show} onHide={()=>setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Others.sure")}</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={()=> {
                        dispatch(removeOfferFromCart(offerId)).then(()=>{
                            setShow(false)
                        })
                    }}>
                        {t("Orders.delete_btn")}
                    </Button>
                    <Button variant="secondary" onClick={()=>setShow(false)}>
                        {t("Orders.cancel_btn")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Button variant="link" className="rounded icon-action"
                    onClick={() => setShow(true)}
            >
                {" "}
                <img src="/img/delete-red.svg" alt=""/>
            </Button>
        </>
    )
}