import Button from "react-bootstrap/Button";
import {useRef, useState} from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";


export default function ScanInfo() {
    const [show, setShow] = useState(false);
    const target = useRef(null);
const { t } = useTranslation();  


    const info = [
        t("Others.find_choose") ,
        t("Others.on_again") ,
        t("Others.scan_products") 
    ]

    return (
      <>
        <Button
          variant={"info"}
          size={"sm"}
          ref={target}
          onClick={() => setShow(!show)}
        >
          {t("Others.info")}
        </Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              {info.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </Tooltip>
          )}
        </Overlay>
      </>
    );
}