import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function ServerError() {
const { t } = useTranslation();  

    return (
      <div
        className={
          "vw-100 vh-100 d-flex justify-content-center align-items-center flex-column "
        }
        style={{ background: "url(../img/login-bg.svg)" }}
      >
        <Alert variant={"danger"}>{t("Others.500")}</Alert>
        <Link to={"/home"} className={"text-light"}>
          {t("Others.back")}
        </Link>
      </div>
    );
}