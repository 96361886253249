import {Row} from "react-bootstrap";
import ShortSpecification from "./shortSpecification";
import React, {useEffect, useState} from "react";
import {shortSpecConfig, catConfig} from "./specificationConfig";
import {useTranslation} from "react-i18next";
import '../../i18n';

const SpecificationList = ({product}) => {


    const productCategory = catConfig.get(product?.category?.id)

    const [utilityClasses, setUtilityClasses] = useState('')
    useEffect(() => {
        if (product?.category?.id !== 1) return
        const array = []
        product?.technicalParameters?.utilityClasses?.forEach(element => {
            array.push(element.name)
        })

        setUtilityClasses(array.join(", "))
    }, [product])

    const getValue = (item) => {

        if (item.hasOwnProperty("customValue")) {

            return item.customValue(product)
        }
        if (item.key3 !== null) {
            if (!product[item.key1]) return
            if (!product[item.key1][item.key2]) return
            if (!product[item.key1][item.key2][item.key3]) return
            return product[item.key1][item.key2][item.key3]
        }
        if (item.key2 !== null) {
            if (!product[item.key1]) return
            if (!product[item.key1][item.key2]) return

            return product[item.key1][item.key2]

        }
        if (item.key1 !== null) {
            if (!product[item.key1]) return

            return product[item.key1]
        }
    }

    const {t} = useTranslation();
    return (

        <Row className="short-specification">
            {
                shortSpecConfig[productCategory]?.map((item, index) => {

                    const value = getValue(item)
                    return (
                        value ?
                            <ShortSpecification
                                key={index}
                                specType={t(item.label)}
                                specData={value}
                            /> : null
                    )
                })
            }
            {
                product?.category?.id === 1 ?
                    <ShortSpecification

                        specType={t("ProductSpec.usability")}
                        specData={utilityClasses}
                    />

                    : null
            }


        </Row>
    )
}
export default SpecificationList