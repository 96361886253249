import Navbar from "react-bootstrap/Navbar";
import { isMobile } from "react-device-detect";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import LanguagePicker from "../languagePicker/languagePicker";
import { ReactComponent as Scan } from "../../svg/Scan.svg";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import CartDraftModal from "../catDraftModal/cartDraftModal";

const NavigationMain = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cartCount = useSelector((state) => state.cart.cartCount);
  const userData = useSelector((state) => state.user.data);
  const { t } = useTranslation();

  return (
    <Navbar
      className={`navbar-main  navbar-lower-padding ${
        window.location.host === "bright-starlight-a50a86.netlify.app"
          ? "test-bg"
          : ""
      }`}
    >
      {parseFloat(userData?.customerLimit) > 0 &&
      userData?.customerData?.canSeeCreditLimit &&
      userData?.customerData?.canSeeCreditMaxLimit ? (
        <Navbar.Text className="text-pill rounded-pill h-40 element-bg-center px-28 me-2">
          {t("Navbar.limit")}:&nbsp;<b>{userData?.customerLimit}</b>
        </Navbar.Text>
      ) : null}
      {/* 
      <Button
        className={"ms-2 me-2 "}
        onClick={() => navigate("/order-table")}
        variant="success"
      >
        Zamow z formularza
      </Button> */}

      {userData?.customerData?.canMakeOrderFromFile ? <CartDraftModal /> : null}

      {isMobile ? (
        <Button className={"ms-3 "} onClick={() => navigate("/scanner")}>
          <Scan />
        </Button>
      ) : null}
      <Link
        variant="link"
        className={`icon-action mx-4 rounded ${
          location.pathname === "/shoppingCart" ? "invert" : ""
        }`}
        to="/shoppingCart"
      >
        <img src="/img/cart.svg" alt="" />
        {cartCount > 0 ? (
          <Badge className="badge-circle" bg="dark">
            {cartCount}
          </Badge>
        ) : null}
      </Link>
      <LanguagePicker />
    </Navbar>
  );
};
export default NavigationMain;
