import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import SmallSwiperSlide from "../smallSwiperSlide";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "axios";
import {Spinner} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const VariantsSlider = () => {
    const {productId} = useParams();
    const {t} = useTranslation()
    const [variants, setVariants] = useState([]);
    const userData = useSelector((state) => state.user.data);
    const [loading, setLoading] = useState(false)
    const getVariants = () => {
        setLoading(true)
        axios.get("/product/related/" + productId).then((resposne) => {
            setVariants(resposne.data.items);
            setLoading(false)
        });
    };
    useEffect(() => {
        getVariants();
    }, []);
    return (
        <div className="swiper-container accordion-swiper-container">
            {
                loading ? <Spinner animation={"border"}/> : !loading && variants.length <= 0 ? t("ProductPage.no_variants") : null
            }
            <Swiper

                breakpoints={{
                    // when window width is >= 640px
                    577: {

                        width: 552,
                        slidesPerView: 1,
                    },
                    768: {

                        width: 688,
                        slidesPerView: 2,
                    },

                    992: {
                        width: 688,
                        slidesPerView: 3,
                    },
                    1200: {
                        width: 811,
                        slidesPerView: 3,
                    },
                    1366: {
                        width: 795,
                        slidesPerView: 3,
                    },
                    1561: {
                        width: 910,
                        slidesPerView: 3,
                    },
                }}
                spaceBetween={10}
                loop={true}
                navigation={variants?.length >= 1}
                modules={[Navigation]}
                className="mySwiper"
            >
                {variants?.map((variant, index) => (
                    <SwiperSlide
                        key={index}
                        className="d-flex flex-column align-items-start">
                        <SmallSwiperSlide
                            id={variant.id}
                            sliderImage={variant.images[0]?.resized?.galleryThumb?.default ? variant.images[0].resized.galleryThumb.default : variant.images[0]?.resized?.thumbnail?.default}
                            sliderProduct={variant.name}
                            imgClass={"w-100"}
                            sliderPrice={userData.customerData.canSeeProductPrice ? `${variant.priceList.minPrice} ${variant.priceList.currency} / ${variant.priceList.conditionUnitTrans}` : ''}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
export default VariantsSlider;