import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import axios from "axios";
import {Image} from "react-bootstrap";
import {languages} from "./languages";

export default function LanguagePicker() {

    const [show, setShow] = useState(false);
    const {t, i18n} = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChange = (lang) => {

        axios.interceptors.request.clear()
        axios.interceptors.request.use(
            async config => {

                config.params = {
                    ...config.params,
                    lang: lang
                }
                return config;
            },
            error => {
                Promise.reject(error)
            });
        i18n.changeLanguage(lang)
        handleClose()
    }

    return (
        <>
            <Button
                variant="link"
                onClick={handleShow}
                className="icon-action ms-3 rounded language-toggler"
            >
                <img src="/img/web.svg" alt=""/>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Others.choose")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        languages?.map((item) => (
                            <Button
                                key={item.lng}
                                size={"sm"}
                                variant={"link"}
                                className="ms-2 "
                                onClick={() => handleChange(item.lng)}
                            >
                                <Image src={"/img/" + item.img} className={"me-2"}/>
                                {item.lng.toUpperCase()}
                            </Button>
                        ))
                    }


                </Modal.Body>
            </Modal>
        </>
    );
}