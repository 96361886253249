import Button from "react-bootstrap/Button";
import { useSwiper } from "swiper/react";
import { ReactComponent as Prev } from "../../svg/prev.svg";
import { ReactComponent as Next } from "../../svg/next.svg";

const NavigationButton = ({ direction = "next", ...rest }) => {
  const swiper = useSwiper();

  return (
    <Button
      variant={"link"}
      onClick={() => {
        if (direction === "next") {
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      }}
      className={`nav-button nav-button--${direction}`}
      {...rest}
    >
      {direction === "next" ? <Next /> : <Prev />}
    </Button>
  );
};
export default NavigationButton;
