import { Col, Row } from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ClientProfile = () => {
  const userData = useSelector((state) => state.user.data);
  const { customerData } = userData;
  const { t } = useTranslation();

  return (
    <>
      <HeaderBar
        header={t("Client.profile")}
        headerBarID="client-profile"
        className="header-page-1386"
      ></HeaderBar>
      <Row>
        <Col className="gray-bg custom-scroll content-container with-header-bar ">
          <PerfectScrollbar>
            <Row className="max-1386 pt-4">
              <Col md={3}>
                <div className={"mb-3"}>{t("Client.data")}</div>

                <div className="shadow-radius-white p-30 client-card ">
                  <div className="fz-12">
                    <div className="fw-bold">
                      {customerData?.payer.fullName}
                    </div>
                    <div>{customerData?.payer.companyName}</div>
                    <div>{customerData?.payer.street}</div>
                    <div>
                      {customerData?.payer.postCode} {customerData?.payer.city}
                    </div>
                    <div>
                      {customerData?.recipient.country == "PL"
                        ? t("Others.poland")
                        : customerData?.recipient.country}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={3}>
                <div className={"mb-3"}>{t("Client.recipient")}</div>

                <div className="shadow-radius-white p-30 client-card ">
                  <div className="fz-12">
                    <div className="fw-bold">
                      {customerData?.payer.fullName}
                    </div>
                    <div>{customerData?.recipient.companyName}</div>
                    <div>{customerData?.recipient.street}</div>
                    <div>
                      {customerData?.recipient.postCode}{" "}
                      {customerData?.recipient.city}
                    </div>
                    <div>
                      {customerData?.recipient.country == "PL"
                        ? t("Others.poland")
                        : customerData?.recipient.country}
                    </div>
                  </div>
                </div>
              </Col>
              {customerData?.extraEmail ? (
                <Col md={3}>
                  <div className={"mb-3"}>{t("Client.contact")}</div>

                  <div className="shadow-radius-white p-30 client-card ">
                    <div className="fz-12">
                      <div className="fw-bold mt-3">{t("Client.mail")}</div>
                      <div>{customerData?.extraEmail}</div>
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )}
              <Col md={3}>
                <div className={"mb-3"}>{t("Client.additional")}</div>

                <div className="shadow-radius-white p-30 client-card ">
                  <div className="fz-12">
                    <div className="fw-bold">{t("Client.worker")}</div>
                    <div className="fw-bold mt-3">
                      {customerData?.canAdminCreateOrderForClient
                        ? t("Others.yes")
                        : t("Others.no")}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="max-1386  mt-4">
              <Col md={3}>
                <div>{t("Client.adresses")}</div>

                <div className="shadow-radius-white p-30 client-card mt-3 ">
                  <div className="fz-12">
                    <div className="fw-bold">
                      {customerData?.payer.fullName}
                    </div>
                    <div>{customerData?.recipient.companyName}</div>
                    <div>{customerData?.recipient.street}</div>
                    <div>
                      {customerData?.recipient.postCode}{" "}
                      {customerData?.recipient.city}
                    </div>
                    <div>
                      {customerData?.recipient.country == "PL"
                        ? t("Others.poland")
                        : customerData?.recipient.country}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {customerData?.canSeeCreditLimit ? (
              <Row className="max-1386 mt-4">
                {customerData?.canSeeCreditMaxLimit ? (
                  <Col md={3}>
                    <div>{t("Client.limits")}</div>

                    <div className="shadow-radius-white p-30 client-card mt-3 ">
                      <div className="fz-12">
                        <div className="fw-bold">{t("Client.max")}</div>
                        <div className={"fz-32 mt-3"}>
                          {userData?.customerLimit} {userData?.currency}
                        </div>
                      </div>
                    </div>
                  </Col>
                ) : null}

                <Col md={3}>
                  <div className={"pt-4"}></div>

                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">{t("Client.saldo")}</div>
                      <div className={"fz-32 mt-3"}>
                        {userData?.saldo} {userData?.currency}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className={"pt-4"}></div>

                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">{t("Client.debt")}</div>
                      <div className={"fz-32 mt-3"}>
                        {userData?.debtsDue} {userData?.currency}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className={"pt-4"}></div>

                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">{t("Client.deadline")}</div>

                      <div className={"fz-32 mt-3"}>
                        {userData?.paymentTerm}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className={"max-1386 pb-5 mt-4"}>
              {customerData?.account ? (
                <Col md={3}>
                  <div>{t("Client.contact")}</div>
                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">
                        {t("Client.commercial_supervisor")}
                      </div>
                      <div>{customerData?.account.name2}</div>
                      {customerData?.account.email ? (
                        <div>{customerData?.account.email}</div>
                      ) : (
                        <></>
                      )}
                      <div>{customerData?.account.phone}</div>
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )}
              {customerData?.support ? (
                <Col md={3}>
                  <div className={"pt-4"}>
                    {console.log(userData.customerData)}
                  </div>
                  <div className="shadow-radius-white p-30 client-card mt-3 ">
                    <div className="fz-12">
                      <div className="fw-bold">
                        {t("Client.account_manager")}
                      </div>
                      <div>{customerData?.support.name2}</div>
                      {customerData?.support.email ? (
                        <div>{customerData?.support.email}</div>
                      ) : (
                        <></>
                      )}
                      <div>{customerData?.support.phone}</div>
                    </div>
                  </div>
                </Col>
              ) : (
                <></>
              )}

              {/*<Col md={3} >*/}
              {/*    <div className={"pt-4"}>*/}

              {/*    </div>*/}

              {/*    <div className="shadow-radius-white p-30 client-card mt-3 ">*/}
              {/*        <div className="fz-12">*/}
              {/*            <div className="fw-bold">Opiekun DOK</div>*/}
              {/*            <div>Jan Kowalski</div>*/}
              {/*            <div>jan@decora.pl</div>*/}
              {/*            <div>605-203-102</div>*/}
              {/*        </div>*/}
              {/*    </div>*/}
              {/*</Col>*/}
            </Row>
          </PerfectScrollbar>
        </Col>
      </Row>
    </>
  );
};
export default ClientProfile;
