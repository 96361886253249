import {useTranslation} from "react-i18next";


function AccordionSpec({accordionSpecType, accordionSpecData, accordionSpecUnit}) {
    const {t} = useTranslation();

    return (
        <>
            <div className="accordion-spec d-flex">
                <div className="accordion-spec-type">
                    <span>{t(accordionSpecType)}</span>
                </div>
                <div className="accordion-spec-data">
                    <span>{accordionSpecData} {accordionSpecUnit}</span>
                </div>
            </div>
        </>
    );
};
export default AccordionSpec;
