import { useEffect, useRef, useState } from "react";
import { ReactComponent as Chevron } from "../../svg/keyboard_arrow_down_black_24dp.svg";
import useOutsideClick from "../../customHooks/useOutsideClick";

const CustomSelect = ({ options = [], onChange, value, req, className }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);
  const ref = useRef(null);
  useOutsideClick(ref, () => {
    setIsOptionsOpen(false);
  });
  useEffect(() => {
    setSelectedOption(value);
  }, [value]);
  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const setSelectedThenCloseDropdown = (value) => {
    setSelectedOption(value);
    setIsOptionsOpen(false);
  };

  const handleKeyDown = (index) => (e) => {
    switch (e.key) {
      case " ":
      case "SpaceBar":
      case "Enter":
        e.preventDefault();
        setSelectedThenCloseDropdown(index);
        break;
      default:
        break;
    }
  };

  return (
    <div className={"custom-select " + className} ref={ref}>
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded={isOptionsOpen}
        onClick={toggleOptions}
        style={req ? { border: "2px solid #ad003e", borderRadius: "5px" } : {}}
      >
        <Chevron
          style={{ transform: `rotate(${isOptionsOpen ? "180" : "0"}deg)` }}
        />

        {req && !selectedOption ? (
          <i className="bi bi-exclamation-octagon text-danger"></i>
        ) : null}
        <span className={"w-100 text-center"}>
          {options?.find((item) => item.unit === selectedOption)?.unitName}
        </span>
      </button>
      <ul
        className={`options ${isOptionsOpen ? "show" : ""}`}
        role="listbox"
        tabIndex={-1}
      >
        {options?.map((option, index) => (
          <li
            key={index}
            id={option}
            role="option"
            aria-selected={selectedOption === option.unit}
            tabIndex={0}
            onKeyDown={handleKeyDown(index + 1)}
            onClick={() => {
              setSelectedThenCloseDropdown(option.unit);
            }}
          >
            {option.unitName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CustomSelect;
