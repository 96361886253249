import axios from "axios";
import i18next from "i18next";
import React, { useState } from "react";
import { FormControl, Form, Spinner, Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchCart, getCartCount } from "../../redux/cartSlice";
import { useLocation } from "react-router-dom";

function CartDraftModal() {
  const { t } = i18next;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertVariant, setAlertVariant] = useState("primary");
  const [msg, setMsg] = useState("");
  const [finished, setFinished] = useState(false);
  const { register, handleSubmit } = useForm();
  const location = useLocation();
  const handleClose = () => {
    console.log(location);
    if (finished) {
      setFinished(false);
      dispatch(getCartCount());
    }
    setAlertVariant("primary");
    setMsg("");
    setShow(false);
  };
  const handleShow = () => setShow(true);
  function submit(data) {
    setLoading(true);
    const form = new FormData();
    form.append("file", data.file[0]);
    axios
      .post("/draft-cart/create", form)
      .then((res) => {
        console.log(res);
        setAlertVariant("success");
        setMsg(t("CartDraft.uploadSuccess"));
        setLoading(false);
        setFinished(true);
      })
      .catch((error) => {
        setAlertVariant("danger");
        if (error.response.status === 410) {
          setMsg("Brak produktów w pliku.");
        } else if (error.response.status === 409) {
          setMsg("Za dużo produktów");
        } else {
          setMsg(t("CartDraft.uploadFail"));
        }
        if (location.pathname === "/shoppingCart") {
          dispatch(fetchCart());
        }
        setLoading(false);
        setFinished(true);
      });
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>
        {t("CartDraft.orderFile")}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("CartDraft.upload")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submit)}>
            <FormControl
              {...register("file")}
              type={"file"}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <div className=" mt-3">
              <span className="d-block">{t("CartDraft.eneterInfo")}:</span>
              <span style={{ whiteSpace: "pre-line" }}>
                - {t("CartDraft.enterInfoArray", { joinArrays: "\n- " })}
              </span>
            </div>

            {loading ? <Spinner size={"sm"} animation="border" /> : null}
            {msg.length ? (
              <Alert className="mt-2" variant={alertVariant}>
                {msg}
              </Alert>
            ) : null}

            <div className="d-flex justify-content-end mt-3">
              <Button
                variant="secondary"
                onClick={handleClose}
                disabled={loading}
                className="me-2"
              >
                {finished ? t("Pop.close") : t("Pop.cancel")}
              </Button>
              {!finished ? (
                <Button variant="success" type="submit" disabled={loading}>
                  {t("Pop.save")}
                </Button>
              ) : null}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CartDraftModal;
