import {createSlice} from "@reduxjs/toolkit";

export const offerSlice = createSlice({
    name: 'offer',
    initialState:{
        offerAlert: false
    },
    reducers:{
        showOfferAlert: (state)=>{
            state.offerAlert = true
        },
        hideOfferAlert: (state)=>{
            state.offerAlert = false
        }
    }
})

export const {showOfferAlert, hideOfferAlert} = offerSlice.actions
export default offerSlice.reducer