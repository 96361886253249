import React, {useState} from "react";
import {Alert, Col, Container, Row, Spinner} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import LoginLeftColumn from "../components/loginLeftColumn";
import {useForm} from "react-hook-form";
import axios from "axios";
import {setToken} from "../redux/userSlice";
import { useTranslation } from "react-i18next";
import '../i18n';

const LoginReset = () => {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false)
  const [message, setMessage] = useState("Wystąpił błąd.")
  const [loading, setLoading] = useState(false)
  const {register, handleSubmit, watch, formState: {errors}} = useForm();
  const { t } = useTranslation();  
  const onSubmit = data => {
    setLoading(true)
    axios.post(
        "/user/forgot-password",
        JSON.stringify(data),

    ).then(response => {
      if (response.status === 200) {
        navigate("/login-mail-sent");

      }
      setLoading(false)
    }).catch(error => {

      if(error.response.status === 401){

        setMessage(error.response.data.message)
      }
      setShowAlert(true)
      setLoading(false)
      console.log(error)
    })
  }


  return (
    <>
      <Container fluid className="main-container login">
        <Row className="my-auto login-wrapper">
          <LoginLeftColumn />
          <Col
            xs="12"
            lg="6"
            xl="7"
            className="d-flex justify-content-center flex-column align-items-center text-center"
          >
            <div>
              <h2>{t("Welcome.reset")}</h2>
              <div className="mt-3">
              {t("Welcome.reset_subtitle")}
              </div>
              <Form
                  onSubmit={handleSubmit(onSubmit)}
              >
                <Form.Group
                  className="mt-5 mb-5 user login"
                  controlId="user-email"
                >
                  <Form.Control
                    type="text"
                    placeholder={t("Welcome.login_placeholder")}
                    className="mb-4"
                    required
                    {...register("username")}
                  />
                </Form.Group>
                
                <Button variant="danger" type="submit" className="w-100 mb-3 border-0" disabled={loading}
                >

                  {loading ? <Spinner size={"sm"} animation="border"/> : t("Welcome.reset_btn")}

                </Button>
                <Alert
                    variant={"danger"}
                    show={showAlert}
                >
                  {message}
                </Alert>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default LoginReset;
