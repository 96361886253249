import { FormControl } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

export default function CustomerProductNumber({ onChange, value }) {
  const { t } = useTranslation();
  const [showInput, setShowInput] = useState(false);

  function handleToggle() {
    setShowInput((state) => {
      if (state) {
        // onChange({ target: { value: null } });
        return false;
      } else {
        return true;
      }
    });
  }

  return (
    <>
      <Button
        onClick={handleToggle}
        variant={"link"}
        className={"text-danger p-0"}
      >
        {t("Cashbox.enter_own_number")}
      </Button>
      {showInput ? (
        <FormControl
          maxLength={35}
          placeholder={t("Pop.order_number")}
          value={value}
          className={"p-1 border-black-80"}
          type={"text"}
          onChange={onChange}
        />
      ) : null}
    </>
  );
}
