import { useEffect, useState } from "react";
import round from "../../helpers/round";
import { useSelector } from "react-redux";

const useSum = (valuesList, minLogistic) => {
  const userData = useSelector((state) => state.user.data);
  const tax = userData?.taxValue ?? 0;
  const shippingCost = parseFloat(
    minLogistic?.shippingToOwnAddressPrice ?? "0"
  );
  const [netShipping, setNetShipping] = useState(shippingCost);
  const [grossShipping, setGrossShipping] = useState(0);
  const deliveryFrom = parseFloat(
    minLogistic?.freeDeliveryFromToOwnAddress ?? "0"
  );
  const [forFreeDelivery, setForFreeDelivery] = useState(deliveryFrom);
  const [netSum, setNetSum] = useState(0);
  const [availableProductsSum, setAvailableProductsSum] = useState(0);
  const [taxValue, setTaxValue] = useState(0);
  const [grosSum, setGrossSum] = useState(0);
  const [sumWeight, setSumWeight] = useState(0);
  const [sumVolume, setSumVolume] = useState(0);

  const compute = () => {
    let newSum = valuesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.val,
      0
    );
    let newAvailableProductsSum = valuesList
      .filter((item) => item.availableNow)
      .reduce((accumulator, currentValue) => accumulator + currentValue.val, 0);
    let weight = valuesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.weight,
      0
    );
    let volume = valuesList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.volume,
      0
    );

    if (newSum >= deliveryFrom) {
      setNetShipping(0);
      setGrossShipping(0);
    } else {
      setNetShipping(shippingCost);
      setGrossShipping(shippingCost + shippingCost * tax);
    }
    const sumWithDelivery =
      newSum + (newSum >= deliveryFrom ? 0 : shippingCost);

    const taxValue = sumWithDelivery * tax;
    const freeDelivery = deliveryFrom - newSum;
    setSumWeight(round(weight));
    setSumVolume(round(volume));
    setNetSum(sumWithDelivery);
    setTaxValue(taxValue);
    setGrossSum(sumWithDelivery + taxValue);
    setForFreeDelivery(freeDelivery > 0 ? freeDelivery : 0);
    setAvailableProductsSum(newAvailableProductsSum);
  };
  useEffect(compute, [valuesList]);

  return {
    netSum,
    taxValue,
    grosSum,
    netShipping,
    grossShipping,
    forFreeDelivery,
    sumVolume,
    sumWeight,
    availableProductsSum,
  };
};
export default useSum;
