import { currencyFormatter } from "../currencyTools";
import i18next from "i18next";

function handleDecimals(number) {
  const round = Math.round(number * 100000) / 100000;
  return `${round}`.replace(".", ",");
}

const handleOrderXlsx = (
  data,
  canSeePrices,
  notSeeProductVolume,
  notSeeProductWeight
) => {
  const { t } = i18next;
  const volumeSum = data.orderProducts
    .map((item) => {
      const { product, orderOrderProduct } = item;
      const vol =
        (product.logisticData.volume / 1000000) * orderOrderProduct.quantity;
      return vol;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const grossWeightSum = data.orderProducts
    .map((item) => {
      const { product, orderOrderProduct } = item;
      const vol = product.logisticData.grossWeight * orderOrderProduct.quantity;
      return vol;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return [
    {
      data: [
        [t("Orders.order_date"), data?.createdOrderDate],
        [
          t("Orders.order_value"),
          `${currencyFormatter(data?.netOrderValue, canSeePrices).replace(
            ".",
            ","
          )} ${data.currency}`,
        ],
        [
          t("Xlsx.value"),
          `${currencyFormatter(
            data.netOrderValue - data.netShipCost,
            canSeePrices
          ).replace(".", ",")} ${data.currency}`,
        ],
        [
          t("Orders.cost"),
          `${currencyFormatter(data?.netShipCost, canSeePrices).replace(
            ".",
            ","
          )} ${data.currency}`,
        ],
        [
          t("Orders.tax"),
          `${currencyFormatter(data.taxOrderValue, canSeePrices).replace(
            ".",
            ","
          )} ${data.currency}`,
        ],
        [
          t("Orders.payment"),
          `${currencyFormatter(data.grossOrderValue, canSeePrices).replace(
            ".",
            ","
          )} ${data.currency}`,
        ],
        [
          t("Orders.volume"),
          `${notSeeProductVolume ? "-" : handleDecimals(volumeSum)} m3`,
        ],
        [
          t("Orders.weight"),
          `${notSeeProductWeight ? "-" : handleDecimals(grossWeightSum)} kg`,
        ],
        [],
        [
          t("Xlsx.product"),
          "EAN",
          t("Xlsx.net_price"),
          t("Xlsx.quantity"),
          t("Xlsx.unit"),
          t("Xlsx.net_total"),
          t("Orders.volume") + " m3",
          t("Orders.weight") + " kg",
        ],
        ...data.orderProducts?.map((product) => [
          product.product.name,
          product.product.ean,
          product.orderOrderProduct.netUnitPrice,
          product.orderOrderProduct.quantity,
          product.orderOrderProduct.unit,
          product.orderOrderProduct.netPriceValue,
          notSeeProductVolume
            ? "-"
            : handleDecimals(
                (product.product.logisticData.volume / 1000000) *
                  product.orderOrderProduct.quantity
              ),
          notSeeProductWeight
            ? "-"
            : handleDecimals(
                product.product.logisticData.grossWeight *
                  product.orderOrderProduct.quantity
              ),
        ]),
      ],
      sheetName: t("Xlsx.order_summary"),
    },
  ];
};
export default handleOrderXlsx;
