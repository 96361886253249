import { Link, useNavigate } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
function SideBarMainLink({ title, link, className = "", counter, sidebarToggle }) {


  return (
    <div className={`sidebar-main-link ${className}`}>
      <Link to={link} className="px-3" onClick={sidebarToggle}>
        {title}{" "}
        <Badge bg="primary" text="dark" className="badge-circle">
          {counter > 0 ? counter : null}
        </Badge>
      </Link>
    </div>
  );
}
export default SideBarMainLink;
