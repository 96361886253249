import React, {useRef, useState} from "react";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/zoom";

// import "./styles.css";

// import required modules
import { Navigation, Thumbs} from "swiper";
import {Image} from "react-bootstrap";

export default function SwiperCom({images}) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [galleryTop, setGalleryTop] = useState(null);
    const imgRef = React.useRef(null);
    const [fullscreen, setFullscreen] = useState(false)
    const handleClick = (e) => {
        imgRef.current.click();
    };

    return (
        <div className={`swiper-container ${fullscreen ? "fullscreen" : null }`} onClick={()=>console.log("con")}>
            <Swiper

                modules={[Thumbs, Navigation]}
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                onSwiper={setGalleryTop}
                navigation={true}
                //mySwiper2
                className={` gallery-top ${fullscreen ? null : ""}`}
                spaceBetween={16}
                slidesPerView={1}
                thumbs={{
                    swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
                }}

            >
                {fullscreen ? <span
                    className="close-button"
                    onClick={() =>
                        setFullscreen(false)
                    }
                ></span> : null}

                {images.map((img, i) => (
                    <SwiperSlide key={img.id} onClick={(e) => {
                        setFullscreen(true)

                        setTimeout(function () {
                            galleryTop.update();
                            // galleryThumbs.update();
                        }, 200);
                    }}>

                        <Image
                            src={img?.resized ? img?.resized?.gallery.default :img.default}
                            ref={imgRef}
                            onClick={handleClick}
                        />

                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                className="mySwiper gallery-thumbs"
                modules={[Thumbs]}
                watchSlidesProgress
                onSwiper={setThumbsSwiper}
                slidesPerView={5}
                spaceBetween={16}
                freeMode={true}

            >
                {images.map((img, i) => (
                    <SwiperSlide key={i}>
                        <Image
                            style={{width: "140px", height: "100px"}}
                            src={img.resized?.galleryThumb?.default ? img.resized.galleryThumb.default : img.default}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
}
