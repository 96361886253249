import MarketingProduct from "./marketingProduct";
import { React, useState } from "react";
import AdditionalProducts from "./additionalProducts";
import { Col, Row } from "react-bootstrap";
import {useTranslation} from "react-i18next";

const MarketingListItem = (props) => {
  const [isActive, setActive] = useState(false);
const {t} = useTranslation()
  const toggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <Row className="product-wrapper flex-column  justify-content-between product " key={props.item.ean}>
        <Col className="d-flex flex-column">
          <MarketingProduct {...props} />
        </Col>
        <Col className="accesories-button-col">
          <Row className="accesories-button-wrapper">
            {props.item.isDedicatedProducts ? (
              <div className="text-center fw-bold fz-10" onClick={toggleClass}>
                <span
                  className={`arrow-left uppercase rotate show-accesories ${
                    isActive ? "opened" : "closed"
                  }`}
                >
                  {isActive ? t("List.collapse") : t("List.expand")}
                </span>
              </div>
            ) : null}
          </Row>
        </Col>
      </Row>

      {isActive ? <AdditionalProducts itemID={props.item.id} /> : null}
    </>
  );
};
export default MarketingListItem;
