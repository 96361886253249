import XLSX from "sheetjs-style";
import i18next from "i18next";

const xlsxExporter = (sheets, fileName, canSeePrices) => {
  const wb = XLSX.utils.book_new();
  const { t } = i18next;

  sheets?.forEach((element, index) => {
    if (!canSeePrices) {
      for (let j = 8; j < element.data.length; j++) {
        element.data[j][2] = `- ${t("Pop.currencry")}`;
        element.data[j][5] = `- ${t("Pop.currencry")}`;
      }
    }
    const ws = XLSX.utils.aoa_to_sheet(element.data);

    for (let i = 1; i <= 8; i++) {
      ws[`A${i}`].s = {
        font: {
          bold: true,
        },
      };
    }
    ws["A10"].s = {
      font: {
        bold: true,
      },
    };
    ws["B10"].s = {
      font: {
        bold: true,
      },
    };
    ws["C10"].s = {
      font: {
        bold: true,
      },
    };
    ws["D10"].s = {
      font: {
        bold: true,
      },
    };
    ws["E10"].s = {
      font: {
        bold: true,
      },
    };
    ws["F10"].s = {
      font: {
        bold: true,
      },
    };
    ws["G10"].s = {
      font: {
        bold: true,
      },
    };
    ws["H10"].s = {
      font: {
        bold: true,
      },
    };
    XLSX.utils.book_append_sheet(wb, ws, element.sheetName);
  });
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};
export default xlsxExporter;
