import {React, useState} from "react";
import TabelProduct from "./product";
import TableDedicated from "./tableDedicated";

function TableRow({item, priceLoading}) {
    const [isActive, setActive] = useState(false);


    const toggleClass = () => {
        setActive(!isActive);
    };
    return (
        <>
          <TabelProduct isActive={isActive} toggleClass={toggleClass} item={item} priceLoading={priceLoading}/>
            {
                isActive ?
                    <TableDedicated itemId={item.id}  />
                    : null
            }
        </>

    );
}

export default TableRow;
