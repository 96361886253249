import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

function EanModal() {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const [values, setValues] = useState([])
    const {t} = useTranslation();
    const [show, setShow] = useState(false);
    const handleInput = (event) => {
        const val = event.target.value.split(" ");
        setValues(val);
    };

    // const handleQuery = (val) => setEanQuery(val.map((item) => `filters[productEan][]=${item}`).join("&"));
    function handleQuery() {
        let q = "?"
        values.forEach(ean => {
            q = q + `filters[productEan][]=${ean}&`
        })
        navigate({pathname: "/marketing-list", search: q})
    }

    useEffect(() => {
        const eanList = searchParams.getAll("filters[productEan][]")
        setValues(eanList)
    }, [searchParams]);

    return (
        <>
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="table-modal-filter"
                show={show}
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("EAN.filter_EAN")}
                    </Modal.Title>
                </Modal.Header>
                <Form>
                    <Modal.Body>
                        <p>{t("EAN.paste_EAN")}</p>
                        <div className="ean-filter-container">
                            <div className="d-flex align-items-center justify-content-center ean">
                                <span className="fw-bold">EAN</span>
                            </div>
                            <div className="ean-numbers-container">
                                <PerfectScrollbar>
                                    {values?.map((item, index) => (
                                        <div className="ean-number" key={item + "" + index}>
                                            {item}
                                        </div>
                                    ))}

                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control
                                            type="text"
                                            placeholder={t("EAN.enter_EAN")}
                                            onChange={handleInput}
                                        />
                                    </Form.Group>
                                </PerfectScrollbar>
                            </div>
                        </div>
                        {values.length ? (
                            <div className={"w-100 d-flex justify-content-center mt-2"}>
                                <Button onClick={() => setValues([])} variant={"danger"}>
                                    {t("EAN.clear_list")}
                                </Button>
                            </div>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="dark"
                            onClick={() => {
                                setShow(false);
                                handleQuery()
                            }}
                        >
                            {t("Pop.save")}
                        </Button>
                        <Button variant="outline-dark" onClick={() => setShow(false)}>
                            {t("Pop.cancel")}
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <Button
                className="text-pill rounded-pill h-40 element-bg-center fw-bold add-ean me-3 ms-3"
                variant={values.length ? "primary" : "link"}
                onClick={() => setShow(true)}
            >
                {t("EAN.add_EAN")}
            </Button>
        </>
    );
}

export default EanModal;
