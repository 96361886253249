import HeaderBar from "../components/headerBar/headerBar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Alert, Col, FormControl, FormGroup, ModalBody, Row, Spinner} from "react-bootstrap";
import OrdersSelect from "../components/reclamation/ordersSelect";
import PerfectScrollbar from "react-perfect-scrollbar";
import CustomTable from "../components/table/customTable";
import {ReactComponent as Trash} from "../svg/RedTrash.svg";
import React, {useEffect, useState} from "react";
import axios from "axios";
import AddProduct from "../components/reclamation/addProduct";
import CommentPreview from "../components/reclamation/commentPreview";
import FilesList from "../components/reclamation/filesList";
import {Link, useNavigate, useParams} from "react-router-dom";
import AddByScan from "../components/reclamation/addByScan";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import {
    addReclamationProduct,
    setOptions,
    setOrderNumber,
    setProducts,
    setReclamationProducts
} from "../redux/reclamationSlice";
import Modal from "react-bootstrap/Modal";
import {isMobile} from "react-device-detect";
import ScanInfo from "../components/reclamation/scanInfo";

const NewReclamation = () => {
    let [orderProducts, setOrderProducts] = useState()
    const dispatch = useDispatch()
    const {t} = useTranslation();
    const userData = useSelector(state => state.user.data)
    const {products, reclamationProducts, orderNumber} = useSelector(state => state.reclamation)
    const [clientComplaintNumber, setClientComplaintNumber] = useState(null)
    const [isEmailInvalid, setIsEmailInvalid] = useState(false);
    const [contactEmail, setContactEmail] = useState(userData?.customerData?.recipient?.email);
    const [loadingProducts, setLoadingProducts] = useState(false)
    const [show, setShow] = useState(false);
    const [sendingError, setSendingError] = useState(null);
    const [orderComplaints, setOrderComplaints] = useState(null);
    const {orderId} = useParams()
    const navigate = useNavigate()
    const getProducts = (sapOrderNumber) => {
        // dispatch(setReclamationProducts([]))
        setLoadingProducts(true)
        axios.get("/order/number/" + sapOrderNumber).then(response => {
            setOrderComplaints(response.data.complaintsForOrder)
            setOrderProducts(response.data.orderProducts)
            dispatch(setOrderNumber(response.data.sapOrderNumber))
            const p = response.data.orderProducts.map(element => ({

                productSapIndex: element.product.sapIndex,

                ean: element.product.ean,

                quantity: element.deliveryOrderProduct.quantity,

                unit: element.deliveryOrderProduct.unit,

                shortUnitName: element.deliveryOrderProduct.shortUnitName,

                name: element.product.name,

                isItemOutsideOrder: false,

                orderPosition: element.orderPosition

            }))

            dispatch(setProducts(p))
            setLoadingProducts(false)
        })

    }
    const clear = () => {
        dispatch(setReclamationProducts([]))
        dispatch(setProducts([]))
        dispatch(setOrderNumber(null))
        setClientComplaintNumber(null)
    }
    useEffect(() => {
        axios.get("/complaint/data").then(response => dispatch(setOptions(response.data.reason)))
        return clear()
    }, [])
    const addProductToReclamation = (product) => {
        dispatch(setProducts(products.map(item => {
            if (item.productSapIndex === product.productSapIndex) {
                return {
                    ...item,
                    quantity: item.quantity - product.quantity,
                }
            } else {
                return item
            }
        })))

        dispatch(addReclamationProduct(product))
    }
    const removeProduct = (product, index) => {
        dispatch(setReclamationProducts(
            reclamationProducts.filter((item, i) => i !== index)
        ))

        dispatch(setProducts(products.map(item => {
            if (item.productSapIndex === product.productSapIndex) {
                return {
                    ...item,
                    quantity: item.quantity + product.quantity,
                }
            } else {
                return item
            }
        })))
    }
    const columns1 = [
        {
            title: t("Reclamations.name"),
            dataKey: "name",
            width: "20%",
        },
        {
            title: "EAN",
            dataKey: "ean",
            width: "20%",
        },
        {
            title: t("Reclamations.count"),
            dataKey: "quantity",
            width: "20%",
        },
        {
            title: t("Reclamations.unit"),
            dataKey: "unit",
            width: "20%",
        },
        {
            title: t("Reclamations.add_to"),
            dataKey: "quantity",
            width: "20%",
            render: (value, product) => (
                <AddProduct
                    orderProducts={orderProducts}
                    defaultProduct={product}
                    onAdd={addProductToReclamation}
                    disabled={value === 0}
                    buttonText={t("Reclamations.add")}

                />
            ),
        },
    ];
    const columns2 = [
        {
            title: t("Reclamations.name"),
            dataKey: "name",
            width: "14.3%",
        },
        {
            title: "EAN",
            dataKey: "ean",
            width: "14.3%",
        },
        {
            title: t("Reclamations.count"),
            dataKey: "quantity",
            width: "14.3%",
        },
        {
            title: t("Reclamations.unit"),
            dataKey: "unit",
            width: "14.3%",
        },
        {
            title: t("Reclamations.reason"),
            dataKey: "reasonName",
            width: "14.3%",
        },
        {
            title: t("Reclamations.expectations"),
            dataKey: "expectancyName",
            width: "14.3%",
        },
        {
            title: t("Reclamations.actions"),
            dataKey: "name",
            width: "14.3%",
            render: (item, product, index) => (
                <div
                    className={
                        "w-100 d-flex justify-content-between align-items-center"
                    }
                >
                    <CommentPreview comment={product?.comment}/>
                    <FilesList files={product?.files}/>
                    <Button
                        onClick={() => removeProduct(product, index)}
                        variant={"link"}
                        className={"p-1"}
                    >
                        <Trash/>
                    </Button>
                </div>
            ),
        },
    ];

    const [files, setFiles] = useState([])
    const [dataProducts, setDataProducts] = useState([]);
    const [sending, setSending] = useState(false)

    const save = async () => {


        let f = null;
        if (files.length === 0) {
            setSending(true)

            const data = {
                clientComplaintNumber: clientComplaintNumber,
                orderNumber: orderNumber,
                products: reclamationProducts,
            }

            setDataProducts(data.products);

            const f2 = []
            data.products.map(async (product, index) => {
                const requests = product.files?.map(async (file, index2) => {
                    let form = new FormData()
                    form.append("file", file)
                    return axios.post("/complaint/file", form)
                })
                f = await axios.all(requests).then(responses => {
                    return responses.map(item => {
                        return {
                            fileId: item.data.id
                        }
                    })
                })
                f2[index] = f;
                if(index + 1 === data.products.length){
                    setFiles(f2);
                }
            })
        }
    }

    useEffect(() => {
        if (files && files.length > 0) {
            const data = {
                clientComplaintNumber: clientComplaintNumber,
                orderNumber: orderNumber,
                clientEmail: contactEmail,
                products: dataProducts.map((product, index) => {
                    const {
                        isItemOutsideOrder,
                        productSapIndex,
                        quantity,
                        unit,
                        reason,
                        expectancy,
                        comment,
                        orderPosition
                    } = product

                    return {
                        isItemOutsideOrder,
                        productSapIndex,
                        quantity,
                        unit,
                        reason,
                        expectancy,
                        comment,
                        orderPosition,
                        files: files[index],


                    };
                })
            }

            console.log(data)

            axios.post("/complaint", data).then(response => {
                setSending(false)
                // removeFile()
                dispatch(setProducts([]))
                dispatch(setOrderNumber(''))
                dispatch(setReclamationProducts([]))
                navigate("/complaints")
            }).catch((error) => {
                setSendingError(error.response.data.message.content)
                setSending(false)
            })
        }
    }, [files]);

    function validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    }


    return (
        <Form>
            <HeaderBar
                header={
                    t("Reclamations.new") +
                    (orderNumber ?? "")
                }
                headerBarID="reclamations"
            >
                <Button
                    disabled={sending || !reclamationProducts.length || isEmailInvalid}
                    variant={"dark"}
                    onClick={() => setShow(true)}
                >
                    {sending ? (
                        <Spinner size={"sm"} animation={"border"}/>
                    ) : (
                        t("Reclamations.sent")
                    )}
                </Button>
            </HeaderBar>
            <Row>
                <Col className="gray-bg custom-scroll content-container with-header-bar  ">
                    <PerfectScrollbar>
                        <div className="max-1386 pb-5 ">
                            <Row className={"pt-3"}>
                                <Col sm={6} md={4}>
                                    {isMobile ? (
                                        <div className={"mb-2 mt-1"}>
                                            <AddByScan
                                                getProducts={getProducts}
                                                addProductToReclamation={addProductToReclamation}
                                                orderProducts={orderProducts}
                                            />
                                        </div>
                                    ) : null}
                                    <div>
                                        <div className={"d-flex  align-items-center mb-2"}>
                                            <p className={"m-0 me-3"}>{t("Reclamations.choose")}</p>
                                            {isMobile ? <ScanInfo/> : null}
                                        </div>

                                        <OrdersSelect onSelect={getProducts} onClear={clear}/>
                                    </div>
                                    <div className={" mt-3"}>
                                        <p>{t("Reclamations.own_nr")}</p>
                                        <FormControl
                                            type={"text"}
                                            onInput={(e) =>
                                                setClientComplaintNumber(e.target.value)
                                            }
                                            value={clientComplaintNumber}
                                            className={"p-1 border-black-80"}
                                            placeholder={t("Reclamations.nr")}
                                        />
                                    </div>
                                    <div className={" mt-3"}>
                                        <p>{t("Reclamations.mail")}</p>
                                        <FormGroup>
                                            <FormControl
                                                type={"email"}
                                                onInput={(e) => {
                                                    setIsEmailInvalid(!validateEmail(e.target?.value))
                                                    setContactEmail(e.target.value)

                                                }}
                                                value={contactEmail}
                                                className={"p-1 border-black-80"}
                                                placeholder={t("Reclamations.mail")}
                                                isInvalid={isEmailInvalid}
                                                required={true}
                                            />
                                            <FormControl.Feedback type={"invalid"}>
                                                Proszę podać email
                                            </FormControl.Feedback>
                                        </FormGroup>

                                    </div>
                                </Col>
                                {orderComplaints?.length ? (
                                    <Col sm={4} className={"mt-3 mt-sm-0"}>
                      <span className={"fw-bold"}>
                        {t("Reclamations.reclamations_another")}
                      </span>
                                        <div className="d-flex flex-column">
                                            {orderComplaints.map((item, index) => (
                                                <Link key={index} to={"/complaints/" + item.id}>
                                                    {t("Reclamations.day")} {item.createdAt}{" "}
                                                    {t("Pop.no")} {item.number}{" "}
                                                </Link>
                                            ))}
                                        </div>
                                    </Col>
                                ) : null}
                            </Row>
                            <div className={"mt-4 p-3 shadow-radius-white"}>
                                <div
                                    className={
                                        "d-flex flex-wrap justify-content-between align-items-center mb-2"
                                    }
                                >
                                    <p className={"m-0"}>{t("Reclamations.add_product")}</p>
                                    <AddProduct
                                        buttonText={t("Reclamations.add_product_outside")}
                                        onAdd={addProductToReclamation}
                                        variant={"dark"}
                                        className={"mt-2 mb-3 m-sm-0"}
                                        defaultProduct={null}
                                    />
                                </div>
                                <CustomTable
                                    columns={columns1}
                                    data={products}
                                    loading={loadingProducts}
                                />
                            </div>
                            <div className={"mt-4 p-3 shadow-radius-white"}>
                                <p>{t("Reclamations.choosen")}</p>
                                <CustomTable
                                    columns={columns2}
                                    data={reclamationProducts}
                                    loading={false}
                                />
                            </div>
                        </div>
                    </PerfectScrollbar>
                </Col>
            </Row>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{t("Reclamations.sure")}</Modal.Title>
                </Modal.Header>
                {sendingError?.length ? (
                    <ModalBody>
                        <Alert variant={"danger"}>{sendingError}</Alert>
                    </ModalBody>
                ) : null}
                <Modal.Footer>
                    <>
                        <Button
                            variant="success"
                            onClick={() => save()}
                            disabled={sending}
                        >
                            {sending ? (
                                <Spinner animation={"border"} size={"sm"}/>
                            ) : (
                                t("Reclamations.sent")
                            )}
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => setShow(false)}
                            disabled={sending}
                        >
                            {t("Reclamations.back")}
                        </Button>
                    </>
                </Modal.Footer>
            </Modal>
        </Form>
    );
}
export default NewReclamation