import {useMemo} from "react";

export default function useCashBoxSum(cashRegister, newAddress, minLogistic, ){
    const {
        shippingToOwnAddressPrice,
        freeDeliveryFromToOwnAddress,
        shippingToOneTimeAddressPrice,
        freeDeliveryFromToOneTimeAddress
    } = minLogistic
    const toOwnAddress = parseFloat(shippingToOwnAddressPrice)
    const toNewAddress = parseFloat(shippingToOneTimeAddressPrice)
    let offersSum = 0
    cashRegister.offers.forEach((element) => {
        offersSum = offersSum + element.reduce((subSum, item) => (subSum + item?.productPrice?.valueNet ?? 0), 0)
    })
    let newSum = cashRegister.items.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.valueNet), offersSum);
    const tax = parseFloat((cashRegister?.items[0]?.percentTax ?? cashRegister.offers[0][0]?.productPrice?.percentTax ) / 100)
    const currency = cashRegister?.items[0]?.currency ?? cashRegister.offers[0][0]?.productPrice?.currency


   return  useMemo(() => {
        let netShipping = 0;
        let grossShipping = 0;
        if (newAddress) {
            if (newSum >= parseFloat(freeDeliveryFromToOwnAddress)) {
                netShipping = 0;
                 grossShipping = 0;

            } else {
                netShipping = (toOwnAddress)
                grossShipping = (toOwnAddress + (toOwnAddress * tax))
            }

        } else {
            if (newSum >= parseFloat(freeDeliveryFromToOneTimeAddress)) {
                 netShipping = 0;
                grossShipping = 0;

            } else {
                netShipping = (toNewAddress)
                grossShipping = (toNewAddress + (toNewAddress * tax))
            }

        }
        let netWholeOrderValue = (newSum + netShipping)
        const taxValue = netWholeOrderValue * tax
        const grossWholeOrderValue = netWholeOrderValue + taxValue


        return {
            netShipping: netShipping,
            grossShipping: grossShipping,
            taxValue: taxValue,
            netWholeOrderValue: netWholeOrderValue,
            grossWholeOrderValue: grossWholeOrderValue,
            tax: tax * 100,
            currency: currency
        }
    }, [newAddress])


}
