import { useSelector } from "react-redux";
import { currencyFormatter } from "../../helpers/currencyTools";
import { useTranslation } from "react-i18next";

function SubTable({
  deliveryPrice,
  forFreeDelivery,
  orderNetTotal,
  tax,
  orderValue,
  subTableWidth,
  subTableFirstCol,
  taxRate,
  currency,
  orderBlockIfNotMet,
  sumWeight,
  sumVolume,
  blockOrdering,
}) {
  const userData = useSelector((state) => state.user.data);
  const { t } = useTranslation();

  return (
    <div className="subtable ms-auto" style={subTableWidth}>
      <div className="subtable-wrapper">
        {userData?.customerData.notSeeProductWeight ? null : (
          <div className="subtable-row">
            <div className="text-end pe-4" style={subTableFirstCol}>
              <span>{t("Orders.weight")}:</span>
            </div>
            <div className="text-center">
              <span>{sumWeight} kg</span>
            </div>
          </div>
        )}
        {userData?.customerData.notSeeProductVolume ? null : (
          <div className="subtable-row">
            <div className="text-end pe-4" style={subTableFirstCol}>
              <span>{t("Orders.volume")}:</span>
            </div>
            <div className="text-center">
              <span>
                {sumVolume} m<sup>3</sup>
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="subtable-wrapper">
        {orderBlockIfNotMet ? null : (
          <div className="subtable-row">
            <div className="text-end pe-4" style={subTableFirstCol}>
              <span>{t("Orders.cost")}:</span>
            </div>
            <div className="text-center">
              <span>
                {currencyFormatter(
                  deliveryPrice,
                  userData.customerData.canSeeProductPrice
                )}{" "}
                {currency}
              </span>
            </div>
          </div>
        )}

        {(userData?.customerData.canSeeProductPrice && forFreeDelivery) ||
        blockOrdering ? (
          <div className="subtable-row text-blue fz-12">
            <div className="text-end pe-4" style={subTableFirstCol}>
              <span className="text-pill rounded-pill ">
                {t("Orders.free_cost")}:
              </span>
            </div>
            <div className="text-center">
              {userData.customerData.canSeeProductPrice ? (
                <span>
                  {currencyFormatter(
                    forFreeDelivery,
                    userData.customerData.canSeeProductPrice
                  )}{" "}
                  {currency}
                </span>
              ) : (
                <span>{t("Orders.min_not_reached")}</span>
              )}
            </div>
          </div>
        ) : null}
      </div>

      <div className="subtable-wrapper">
        <div className="subtable-row">
          <div className="text-end pe-4" style={subTableFirstCol}>
            <span>{t("Orders.netto")}:</span>
          </div>
          <div className="text-center">
            <span>
              {currencyFormatter(
                orderNetTotal,
                userData.customerData.canSeeProductPrice
              )}{" "}
              {currency}
            </span>
          </div>
        </div>
        <div className="subtable-row fz-15  fw-normal">
          <div className="text-end pe-4" style={subTableFirstCol}>
            <span className="">
              {t("Orders.tax1")} ({taxRate}%):
            </span>
          </div>
          <div className="text-center">
            <span>
              {currencyFormatter(tax, userData.customerData.canSeeProductPrice)}{" "}
              {currency}
            </span>
          </div>
        </div>
      </div>
      <div className="subtable-wrapper subtable-single-wrapper">
        <div className="subtable-row fz-24">
          <div>
            <span>
              {t("Orders.payment")}:{" "}
              {currencyFormatter(
                orderValue,
                userData.customerData.canSeeProductPrice
              )}{" "}
              {currency}
            </span>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default SubTable;
