import Button from "react-bootstrap/Button";
import {Badge, Col, Image, Row, Spinner} from "react-bootstrap";
import HeaderBar from "../components/headerBar/headerBar";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Link, useNavigate, useParams} from "react-router-dom";
import CustomTable from "../components/table/customTable";
import {useEffect, useState} from "react";
import axios from "axios";
import useOffer from "../customHooks/useOffer";
import OfferCounter from "../components/offer/offerCounter";
import useIsReady from "../customHooks/useIsReady";
import {currencyFormatter} from "../helpers/currencyTools";
import {useDispatch, useSelector} from "react-redux";
import {getCartCount} from "../redux/cartSlice";
import RejectOffer from "../components/offer/rejectOffer";
import {hideOfferAlert, showOfferAlert} from "../redux/offerSlice";
import {getUserData} from "../redux/userSlice";
import OfferExtraQuantity from "../components/offer/offerExtraQuantity";
import { useTranslation } from "react-i18next";


const OfferDetails = () => {
    const navigate = useNavigate()
    const {offerId} = useParams()
    const [loading, setLoading] = useState(true)
    const [processing, setProcessing] = useState(false)
    const dispatch = useDispatch()
const { t } = useTranslation();  
    const [data, setData] = useState();
    const {
        products,
        setProducts,
        handleQuantity,
        quantitySum,
        handleExtraQuantity,
        extraQuantitySum,
        valueSum
    } = useOffer()
    const isReady = useIsReady(quantitySum, extraQuantitySum, data)
    const userData = useSelector((state) => state.user.data);

    useEffect(() => {

            setLoading(true)
            axios.get('/offer/' + offerId, ).then(response => {

                setData(response.data);
                setProducts(response.data);
                handleColumns(response.data.promotionType)
                setLoading(false)
            })
            axios.post(`/offer/${offerId}/show`, {}).then(()=>{
                dispatch(getUserData())
            })


        },
        [])
    const handleImageUrl = (images)=>{
        const newImages = [
            ...images.filter(item => item.category === "Packshot"),
            ...images.filter(item => item.category !== "Packshot")
        ]

        return newImages[0]?.resized?.galleryThumb?.default ?? newImages[0].default
    }
    const handleAccept = () => {
        setProcessing(true)
        const data = {
            products: products.filter(item => item.quantity > 0).map(element => {

                return {
                    productSapIndex: element.product.sapIndex,
                    quantity: element.quantity
                }
            }),
            extraProducts: products.some(element => element.extraQty > 0) ?
                products.filter(item => item.extraQty > 0).map(element => {
                    return {
                        productSapIndex: element.product.sapIndex,
                        quantity: element.extraQty
                    }
                }) : null

        }

        axios.post("/offer/" + offerId + "/confirm", data).then(() => {

            dispatch(getCartCount())
            dispatch(showOfferAlert())

            setTimeout(() => {
                dispatch(hideOfferAlert())

            }, 5000)
            setProcessing(false)

            navigate("/offers")

        })

    }
    const initColumns = [
      {
        title: t("Offer.net_price"),
        dataKey: "priceList",
        width: "15%",
        render: (val, item) => {
          let promotionPriceList = item.promotionPriceList;
          return userData?.customerData?.canSeeProductPrice ? (
            item.isExtra ? (
              `0.01 ${item.priceList?.currency ?? ""} / ${
                item.priceList?.conditionUnitTrans ?? ""
              } `
            ) : item.isCustom ? (
              "0.01"
            ) : item.promotionPriceList ? (
              <div className={"d-flex flex-column"}>
                <span className={"text-decoration-line-through"}>
                  {val.minPrice === val.maxPrice
                    ? val.minPrice
                    : `${val.minPrice} - ${val.maxPrice}`}{" "}
                  {val.currency}
                </span>
                <span>
                  {promotionPriceList?.minPrice === promotionPriceList?.maxPrice
                    ? promotionPriceList?.minPrice
                    : `${promotionPriceList?.minPrice} - ${promotionPriceList?.maxPrice}`}{" "}
                  {promotionPriceList?.currency} /{" "}
                  {promotionPriceList.conditionUnitTrans}
                </span>
              </div>
            ) : (
              `${
                val.minPrice === val.maxPrice
                  ? val.minPrice
                  : `${val.minPrice} - ${val.maxPrice}`
              } ${val.currency} / ${val.conditionUnitTrans}`
            )
          ) : item.isExtra ? (
            `- ${item.priceList?.currency ?? ""} / ${
              item.priceList?.conditionUnitTrans ?? ""
            } `
          ) : item.isCustom ? (
            `- ${val.currency}`
          ) : item.promotionPriceList ? (
            <div className={"d-flex flex-column"}>
              <span className={"text-decoration-line-through"}>
                - {val.currency}
              </span>
              <span>
                {promotionPriceList?.minPrice === promotionPriceList?.maxPrice
                  ? "-"
                  : "-"}{" "}
                {val.currency} / {promotionPriceList.conditionUnitTrans}
              </span>
            </div>
          ) : (
            `- ${val.currency} / ${val.conditionUnitTrans}`
          );
        },
      },
      {
        title: t("Offer.quantity"),
        dataKey: "quantity",
        width: "15%",
        render: (val, item) => {
          const { isExtra, availableUnit, baseUnit, maxQty } = item;

          return (
            <div
              className={
                "d-flex flex-column justify-content-evenly align-items-center w-100 h-100"
              }
            >
              {isExtra ? (
                <OfferExtraQuantity item={item} />
              ) : item.isArchive ? (
                `${item.isCustom ? t("Offer.net_price") : val} ${
                  item.priceList?.conditionUnit ?? ""
                }`
              ) : item.isCustom ? (
                t("Offer.unit")
              ) : (
                <>
                  <div>{availableUnit?.shortUnitName}</div>
                  <OfferCounter
                    className={"counter-border"}
                    quantity={val}
                    setQuantity={(v) =>
                      handleQuantity(v, item.product.sapIndex)
                    }
                    max={maxQty}
                  />
                  <div>
                    {(
                      val *
                      (availableUnit?.counter / availableUnit?.denominator)
                    ).toFixed(3)}{" "}
                    {baseUnit?.shortUnitName}{" "}
                    {baseUnit?.unit !== baseUnit?.defaultUnit ? (
                      <>
                        = {item.cartQuantity ?? 0}{" "}
                        {baseUnit?.shortDefaultUnitName}
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          );
        },
      },
      {
        title: t("SummaryTable.net_total"),
        dataKey: "value",
        width: "15%",
        render: (val, item) =>
          item.isCustom
            ? `0.01`
            : `${currencyFormatter(
                val,
                userData?.customerData.canSeeProductPrice
              )} ${item.priceList.currency ?? ""}`,
      },
    ];
    const [columns, setColumns] = useState(initColumns)

    const handleColumns = (type) => {
        setColumns(state => {
            return [
              {
                title: t("Pop.product"),
                dataKey: "product",
                width: type === "first" ? "40%" : "55%",
                render: (product, item) =>
                  !item.isCustom ? (
                    <Row className={"w-100 "}>
                      <Col md={4} xxl={3} className={"image-col"}>
                        <Link to={"/product/" + product.id}>
                          <Image
                            className={"ratio ratio-4x3 "}
                            style={{
                              aspectRatio: "4/3",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                            src={handleImageUrl(product.images)}
                          />
                        </Link>
                      </Col>
                      <Col
                        md={8}
                        xxl={9}
                        className={
                          " text-start d-flex flex-column justify-content-evenly"
                        }
                      >
                        {item.isExtra ? (
                          <Badge bg="primary" style={{ width: "fit-content" }}>
                            {t("Offer.promo")}
                          </Badge>
                        ) : null}
                        <Link to={"/product/" + product.id}>
                          <h3 className={"mb-0"}>{product.name}</h3>
                        </Link>
                        <p className={"mb-0"}>
                          <b>{t("List.brand")}:</b> {product.brand?.name ?? ""}
                        </p>
                        <p className={"mb-0"}>
                          <b>EAN:</b> {product.ean ?? ""}
                        </p>
                      </Col>
                    </Row>
                  ) : (
                    <Row className={"w-100"}>
                      <Col md={4} xxl={3} className={"image-col"}>
                        <Image
                          className={"ratio ratio-4x3 "}
                          style={{
                            aspectRatio: "4/3",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          src={item.url}
                        />
                      </Col>
                      <Col
                        md={8}
                        xxl={9}
                        className={
                          " text-start d-flex flex-column justify-content-evenly"
                        }
                      >
                        <Badge bg="primary" style={{ width: "fit-content" }}>
                          {t("Offer.promo_quantity")}
                        </Badge>
                        <h3 className={"mb-0"}>{item.prizeName}</h3>
                      </Col>
                    </Row>
                  ),
              },
              ...state,
            ];
        })

        switch (type) {
            case "first":
                setColumns(state => {
                    return [...state,
                        {
                            title: t("Offer.promo_quantity"),
                            dataKey: "extraQty",
                            width: "15%",
                            render: (val, item) => (
                                item.isArchive ? `${val} ${item.priceList?.conditionUnitTrans}` :
                                    !item.isCustom ? <div className={"d-flex flex-column align-items-center"}>
                                        0,01 {item.priceList.currency} / {item.priceList.conditionUnitTrans}
                                    <OfferCounter quantity={val}
                                                  setQuantity={(v) => handleExtraQuantity(v, item.product.sapIndex)}
                                                  max={item.extraMaxQty}
                                                  className={"counter-border"}

                                    />
                                    {(val * (item.availableUnit?.counter / item.availableUnit?.denominator)).toFixed(3)} {item.baseUnit.shortUnitName}
                                    {
                                        item.baseUnit.unit !== item.baseUnit?.defaultUnit ?
                                            <>
                                                {" "}= {item.extraCartQuantity ?? 0} {item.baseUnit?.shortDefaultUnitName}
                                            </> : null

                                    }
                                </div> : ""
                            )
                        }
                    ]
                })
                break

        }
    }
// const offerUnit = data?.products[0].find(item => item.)
    return loading ? (
      <Spinner animation={"border"} />
    ) : (
      <>
        <HeaderBar
          header={t("Offer.details") + " " + data?.offerName}
          headerBarID="offer-details"
        >
          <div className="d-flex py-3 gap flex-wrap justify-content-end">
            {!data?.isArchive ? (
              <div className="d-flex flex-column text-end justify-content-center">
                <span>
                  {t("Offer.quantity")}: {quantitySum}/{data?.numberOfProduct}{" "}
                  {data?.products[0]?.availableUnit?.shortUnitName}
                </span>
                {data?.promotionType === "first" ? (
                  <span>
                    {t("Offer.promo_quantity")}: {extraQuantitySum}/
                    {data.promotionDetails?.numberOfProduct}{" "}
                    {data?.products[0]?.availableUnit?.shortUnitName}
                  </span>
                ) : null}
              </div>
            ) : null}

            {data.isArchive ? (
              t("Offer.archival")
            ) : (
              <div>
                <Button
                  disabled={!isReady || processing}
                  variant="success"
                  className="fz-12 m-1"
                  onClick={handleAccept}
                >
                  {processing ? (
                    <Spinner animation={"border"} size={"sm"} />
                  ) : (
                    t("Offer.accept")
                  )}
                </Button>
                <RejectOffer offerId={offerId} />
              </div>
            )}
          </div>
        </HeaderBar>
        <Row>
          <Col className="gray-bg custom-scroll content-container with-header-bar ">
            <PerfectScrollbar>
              <div className=" pb-5 pt-4">
                <div className="d-flex gap mt-4 pt-3 flex-wrap cards-wrapper">
                  <div className="shadow-radius-white p-30 fz-12 text-uppercase">
                    <div className="fw-bold">{t("Offer.status")}:</div>
                    <div className="d-flex align-items-center my-2">
                      <div>{data.offerStatus}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">{t("Offer.name")}:</div>
                      <div>{data.offerName}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">{t("Offer.number")}:</div>
                      <div>{data.offerNumber}</div>
                    </div>
                    {data.orderNumber ? (
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Offer.promo_quantity")}:
                        </div>
                        <div>{data.orderNumber}</div>
                      </div>
                    ) : null}
                    {data.clientOrderNumber ? (
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Offer.number_client")}:
                        </div>
                        <div>{data.clientOrderNumber}</div>
                      </div>
                    ) : null}
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {t("Offer.creation_date")}:
                      </div>
                      <div>{data.startAt}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {t("Offer.expiration_date")}:
                      </div>
                      <div>{data.endAt}</div>
                    </div>
                    <div className="d-flex">
                      <div className="fw-bold me-1">
                        {t("Offer.quantity_required")} {data.unitOfProduct} :
                      </div>
                      <div>{data.numberOfProduct}</div>
                    </div>
                    {data?.promotionType === "first" ? (
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Offer.promo_quantity")} {data.unitOfProduct} :
                        </div>
                        <div>
                          {data.promotionDetails?.numberOfProduct ?? ""}
                        </div>
                      </div>
                    ) : null}

                    {data?.promotionType === "third" ? (
                      <div className="d-flex">
                        <div className="fw-bold me-1">
                          {t("Offer.discount")} :
                        </div>
                        <div>
                          {data.promotionDetails?.percentValue + "%" ?? ""}
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {data.offerDescription ? (
                    <div>
                      <div className="shadow-radius-white p-30">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.offerDescription,
                          }}
                        ></div>
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="main-content with-header mt-5">
                  <div>
                    <h2>{t("Offer.sum")} </h2>
                  </div>
                  {data ? (
                    <CustomTable
                      columns={columns}
                      data={products}
                      loading={false}
                      summary={{
                        justify: "end",
                        width: data?.promotionType === "first" ? "60%" : "45%",
                        content: (
                          <>
                            <div className={"p-3 "}>
                              {t("Offer.net_sum")}:{" "}
                              {currencyFormatter(
                                valueSum,
                                userData?.customerData.canSeeProductPrice
                              )}{" "}
                              {data.products[0]?.priceList.currency ?? ""}
                            </div>
                          </>
                        ),
                      }}
                    />
                  ) : null}

                  {/*<OrderSummary />*/}
                </div>
              </div>
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    );
};
export default OfferDetails;
